import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { isApp } from '../helpers/window';
import { useFetch } from './api/useFetch';

const getParamsToKeep = (pathname: string) => {
    const paramsToKeep = [
        'clubid',
        'status',
        'member',
        'name',
        'token',
        'variant',
        'app',
        'langCountry',
        // General utm parameters
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_source_platform',
        'utm_creative_format',
        'utm_marketing_tactic',
        // Google Ads parameters
        'gclid',
        'dclid',
        'wbraid',
        // Facebook and other social media parameters
        'fbclid',
        'ttclid',
        'scid',
        'li_fat_id',
        'twclid',
        'epik',
        'rdt_cid',
        // Other utm parameters
        'msclkid',
        '_fbp',
        '_ga',
        '_gl'
    ];

    if (pathname.startsWith('/debt')) {
        paramsToKeep.push('id', 'lang', 'lcid', 'valid');
    }

    if (pathname.startsWith('/membership/changed')) {
        paramsToKeep.push('membershipType');
    }

    return paramsToKeep;
};

export const useCleanUrl = () => {
    const { data: isAuth } = useFetch<boolean>('/authentication/is-auth');
    const [searchParams, setSearchParams] = useSearchParams();
    const { hash, pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isApp() || !isAuth) return;

        const paramsToKeep = getParamsToKeep(pathname);

        for (const [key] of Array.from(searchParams.entries())) {
            if (paramsToKeep.includes(key)) continue;

            searchParams.delete(key);
        }

        setSearchParams(searchParams);
        navigate({ search: searchParams.toString(), hash });
    }, [isAuth]);
};
