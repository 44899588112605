import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { InformationBlock } from '@src/components/InformationBlock/InformationBlock';
import { Section } from '@src/components/Layout/Section';
import { Wrapper } from '@src/components/Layout/Wrapper';

export const PrepaidRenewalSection = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Section>
                <InformationBlock data-testid="prepaid-renewal__section">
                    {t('renewal.overview.body')}
                    <div>
                        <ButtonLink to="/renew-membership" data-testid="prepaid-renewal__button">
                            {t('renewal.overview.link')}
                        </ButtonLink>
                    </div>
                </InformationBlock>
            </Section>
        </Wrapper>
    );
};
