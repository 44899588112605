import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@src/components/Icons/icons/ArrowRight.icon';
import DownloadPlain from '@src/components/Icons/icons/DownloadPlain.icon';
import { Text } from '@src/components/Text/Text';
import { AUTH_SERVICE_FE_DOMAIN } from '@src/services/utilities/env.service';

export default function PersonalDataManagement(): JSX.Element {
    const { t } = useTranslation();

    return (
        <div
            data-cy="personal-data-management"
            className="divide-y divide-anthracite-74 border-b border-anthracite-74"
        >
            <Link
                to={`${AUTH_SERVICE_FE_DOMAIN}/reset-password`}
                data-cy="change-password"
                className="flex justify-between items-center py-3"
            >
                <div className="grow">
                    <Text size="s" className="text-anthracite font-bold font-heading-treble">
                        {t('541')}
                    </Text>
                </div>
                <div className="text-orange w-6 h-6 p-1">
                    <ArrowRightIcon />
                </div>
            </Link>

            <Link
                data-cy="marketing-preferences-link"
                to="marketing-preferences"
                className="flex justify-between items-center py-3"
            >
                <div className="grow">
                    <Text size="s" className="text-anthracite font-bold font-heading-treble">
                        {t('marketing.preferences')}
                    </Text>
                </div>
                <div className="text-orange w-6 h-6 p-1">
                    <ArrowRightIcon />
                </div>
            </Link>

            <Link
                to="/member/get-data-json"
                type="json"
                download="my-basic-fit-data.json"
                target="_blank"
                rel="noreferrer"
                id="download-data-button"
                className="flex justify-between items-center py-3"
                data-testid="download-data"
            >
                <div className="grow text-ellipsis">
                    <Text size="s" className="text-anthracite font-bold font-heading-treble">
                        {t('146') + ' (JSON)'}
                    </Text>
                </div>
                <div className="text-orange w-6 h-6">
                    <DownloadPlain />
                </div>
            </Link>
        </div>
    );
}
