import {
    getTranslatedPaymentInterval,
    isInInitialContractPeriod
} from '@src/utils/helpers/memberHelpers';
import i18next from 'i18next';

/**
 * The payment interval (pmsd_interval) is used to determine the length of:
 * - minimal contract period
 * - payment schedule interval
 * - direct debit interval
 * todo: move to more generic location in codebase, something like src/utils/helpers/enums.ts
 */

export enum PaymentInterval {
    FiftyTwoWeeks = '52weeks',
    FiftyYear = '50year', // test members
    FourWeeks = 'fourWeeks',
    FourYears = '4years',
    HalfYear = 'halfYear',
    OneMonth = 'oneMonth',
    OneWeek = 'oneWeek',
    Quarter = 'quarter',
    ThreeMonths = '3month',
    ThreeYear = '3year',
    TwoMonths = 'twoMonths',
    TwoWeeks = 'twoWeeks',
    TwoYears = 'twoYears',
    Year = 'year'
}

/**
 * Checks if the contract is a flex contract by comparing the minimal contract period and the payment interval
 */
export function isFlexContract(
    minimumContractPeriod: PaymentInterval,
    paymentInterval: PaymentInterval
) {
    return (
        minimumContractPeriod === PaymentInterval.FourWeeks &&
        paymentInterval === PaymentInterval.FourWeeks
    );
}

/**
 *
 * @param paymentInterval
 * @param contractLength
 * @param contractEndDate
 * @returns True if the member has a 52-week contract with 4 weekly payment intervals and is still in the initial contract period (MCP)
 */
export function isRegularInContract(
    paymentInterval: PaymentInterval,
    contractLength: PaymentInterval,
    contractEndDate: string | null
): boolean {
    return (
        (paymentInterval === PaymentInterval.FourWeeks ||
            paymentInterval === PaymentInterval.OneMonth) &&
        contractLength === PaymentInterval.FiftyTwoWeeks &&
        isInInitialContractPeriod(contractEndDate)
    );
}

/**
 *
 * @param paymentInterval
 * @param contractLength
 * @param contractEndDate
 * @returns True if the member has a 52-week contract with 4 weekly payment intervals and is outside of the initial contract period (MCP)
 */
export function isRegularOutOfContract(
    paymentInterval: PaymentInterval,
    contractLength: PaymentInterval,
    contractEndDate: string | null
): boolean {
    return (
        paymentInterval === PaymentInterval.FourWeeks &&
        contractLength === PaymentInterval.FiftyTwoWeeks &&
        isInInitialContractPeriod(contractEndDate) === false
    );
}

/**
 *
 * @param paymentInterval
 * @param contractLength
 * @param contractEndDate
 * @returns True if member has a prepaid 52-week contract with yearly payment intervals and is still in the initial contract period (MCP)
 */
export function isPrepaidInContract(
    paymentInterval: PaymentInterval,
    contractLength: PaymentInterval,
    contractEndDate: string | null
): boolean {
    return (
        (paymentInterval === PaymentInterval.FiftyTwoWeeks ||
            paymentInterval === PaymentInterval.Year) &&
        contractLength === PaymentInterval.FiftyTwoWeeks &&
        isInInitialContractPeriod(contractEndDate)
    );
}

export const priceInterval = (interval: PaymentInterval) => {
    return `${i18next.t('prefix.per')} ${getTranslatedPaymentInterval(interval)}`;
};

/**
 * Returns a formatted price for given locale
 * fr-FR -> 1234,56 €
 * en-GB -> €1,234.56
 */
export const getPrice = (amount: number, lang: string, options?: { format: 'withDigits' }) => {
    const { format } = options || {};
    const fractionDigits = format === 'withDigits' || !Number.isInteger(amount);
    return new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: fractionDigits ? 2 : 0,
        maximumFractionDigits: fractionDigits ? 2 : 0
    }).format(amount);
};

/**
 * Returns a formatted number for given locale
 */
export const getNumber = (amount: number, lang: string) =>
    amount.toLocaleString(lang, { minimumFractionDigits: 2 });
