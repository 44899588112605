import { getLocalDateString } from '@src/utils/helpers/date';
import { getTranslatedPaymentInterval } from '@src/utils/helpers/memberHelpers';
import { isFlexContract } from '@src/utils/helpers/priceHelpers';
import i18next from 'i18next';
import { DateTime } from 'luxon';

import { MemberDataProps } from 'types/member.types';

export function getMembershipStatusKey(member: MemberDataProps): string {
    if (member.end_date_g !== null && member.payment_plan.cancels_automatically === false) {
        // Member cancelled their membership manually: status is cancelled
        return 'cancel_membership.confirmation.status_text';
    }
    if (member.membership_status_g.toLowerCase() === 'frozen') {
        return 'freeze.membership.status.frozen';
    }
    // Default status is active
    return 'addons.banner.active';
}

export function getContractDescription(member: MemberDataProps): string {
    if (!member.payment_plan) return '';

    const { cancels_automatically, minimal_contract_period, interval } = member.payment_plan;

    // Membership will be cancelled automatically
    if (cancels_automatically === true) return i18next.t('contract.details.cancelled.no.renewal');

    if (member.end_date_g !== null) {
        // Member cancelled their membership manually
        const translation = i18next.t('cancel_membership.confirmation.status_text');
        return translation.replace('%end-date%', member.end_date_g);
    }

    const paymentInterval = getTranslatedPaymentInterval(interval);

    if (isFlexContract(minimal_contract_period, interval)) {
        const translation = i18next.t('membership.flexAutoRenewal');
        return translation
            .replace('%renewalcontractperiod%', `<strong>${paymentInterval}</strong>`)
            .replace('%notice%', `<strong>${paymentInterval}</strong>`);
    }

    const contractLength = getTranslatedPaymentInterval(minimal_contract_period);
    const inContract = DateTime.fromISO(member.contract_end_date_g) > DateTime.now();

    // Member is still in their contract period, and will be charged automatically every payment interval
    if (inContract) {
        const translation = i18next.t('contract.details.running.auto.renewal');
        return translation
            .replace('%date%', `<strong>${getLocalDateString(member.contract_end_date_g)}</strong>`)
            .replace('%contractPeriod%', `<strong>${contractLength}</strong>`)
            .replace('%paymentInterval%', `<strong>${paymentInterval}</strong>`);
    }

    // Member has fulfilled their contract, and will be charged automatically every payment interval
    const translation = i18next.t('contract.details.stopped.auto.renewal');
    return translation
        .replace('%contractPeriod%', `<strong>${contractLength}</strong>`)
        .replace('%paymentInterval%', `<strong>${paymentInterval}</strong>`);
}
