import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Skeleton, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Loader } from '@src/components/Loader/Loader';
import { MemberPriceContract } from '@src/components/Price/MemberPriceContract';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { useMember } from '@src/services/member/MemberProvider';
import { formatDate } from '@src/utils/helpers/date';
import { getLastPaymentData, isEmployee } from '@src/utils/helpers/memberHelpers';
import { isPrepaidInContract } from '@src/utils/helpers/priceHelpers';
import { DebitsProps } from '@src/utils/hooks/api/types/debits';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import DOMPurify from 'isomorphic-dompurify';
import { DateTime } from 'luxon';

import { toLocal } from 'services/date-converter';
import { ContractRenewalInfo } from '../ContractRenewalInfo/ContractRenewalInfo';
import { ReverseCancellation } from '../ReverseCancellation/ReverseCancellation';
import { getContractDescription, getMembershipStatusKey } from './utils';

export const MembershipDetails = () => {
    return (
        <>
            <ReverseCancellation />
            <Box variant="mint-top">
                <LoaderWrapper />
                <ContractInformation />
                <MembershipDetailsTable />
                <ContractRenewalInfo />
            </Box>
        </>
    );
};

const LoaderWrapper = () => {
    const { state: member } = useMember();
    if (member.membership) return null;

    return (
        <div className="flex flex-col items-center justify-center">
            <Loader />
        </div>
    );
};

const ContractInformation = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();

    const {
        data: debits,
        isError,
        isLoading
    } = useFetch<DebitsProps>('/payments/v2/get-debits?maxRows=13');

    const { cancels_automatically, minimal_contract_period, interval } = member.payment_plan ?? {};

    const isMembershipCancelled = member.end_date_g !== null;
    const isCancelledManually = isMembershipCancelled && cancels_automatically === false;
    const paymentData = getLastPaymentData(debits, member.end_date_g);
    const scheduledFeesDates = Object.keys(debits?.upcomingFees || {});
    const nextPaymentDate = scheduledFeesDates[0] ?? '';

    const tableData = [];

    if (isMembershipCancelled || cancels_automatically === true) {
        const statusKey = getMembershipStatusKey(member);
        tableData.push([
            t('cancel_membership.confirmation.status'),
            <Bold data-testid="contract-status" key={statusKey}>
                {t(statusKey)}
            </Bold>
        ]);

        // show the last payment date unless the contract is fully paid
        const isContractPaid = isPrepaidInContract(
            interval,
            minimal_contract_period,
            member.contract_end_date_g
        );

        if (paymentData && isContractPaid === false) {
            tableData.push([
                t('membership.cancellation.payment.last_payment'),
                <Bold data-testid="contract-last-payment" key={String(paymentData.date)}>
                    {paymentData.date}
                </Bold>
            ]);
        }

        tableData.push([
            t('cancellation.overview.page.end.date'),
            <Bold data-testid="contract-end-date" key={member.end_date_g}>
                {formatDate(member.end_date_g)}
            </Bold>
        ]);
    }

    if (isMembershipCancelled === false && cancels_automatically === false) {
        tableData.push([
            t('membership.nextPayment'),
            <Bold key={nextPaymentDate}>{nextPaymentDate}</Bold>
        ]);
    }
    if (isError) return null;
    if (isEmployee(member)) return null;

    return (
        <>
            <Heading size={6} data-testid="contract-details-title" className="mb-6">
                {t(
                    isCancelledManually
                        ? 'cancel_membership.confirmation.section_title'
                        : 'membership.contractInformation'
                )}
            </Heading>
            <Text
                data-testid="contract-description"
                className={getContractDescription(member) ? 'mb-6' : 'hidden'}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(getContractDescription(member))
                }}
            />
            {isLoading ? (
                <Skeleton type="text" lines={3} />
            ) : (
                <TableFlex data={tableData} size="s" className="mb-6" />
            )}
        </>
    );
};

const MembershipDetailsTable = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();

    const isMembershipCancelled = member.end_date_g !== null;
    const { cancels_automatically } = member.payment_plan || {};
    const isActiveMembership = isMembershipCancelled === false && cancels_automatically === false;
    const contractEndDate = DateTime.fromISO(member.contract_end_date_g);
    const isMemberInContract = contractEndDate > DateTime.now();

    const details = [
        [t('40'), <Bold key={useId()}>{member.membership.display_name}</Bold>],
        [t('41'), <Bold key={useId()}>{member.membership.display_duration}</Bold>],
        [t('43'), <Bold key={useId()}>{toLocal(member.membership.start_date_g)}</Bold>],
        [t('100'), <MemberPriceContract key={useId()} className="justify-end" />],
        [t('454'), <Bold key={useId()}>{member.membership_number_s}</Bold>]
    ];

    return (
        <>
            <Heading size={6} data-testid="membership-details-title" className="mb-2">
                {t('membership.membershipDetails')}
            </Heading>
            <Text
                data-testid="contract-cancellation-description"
                className={isMemberInContract && isActiveMembership ? 'mb-2' : 'hidden'}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        t('membership.cancellationGeneric').replace(
                            '%date%',
                            formatDate(member.membership.contract_end_date_g)
                        )
                    )
                }}
            />
            <TableFlex data={details} size="s" className="mt-4" />
        </>
    );
};
