import i18next from 'i18next';
import { DateTime } from 'luxon';

import { toLocal } from '../../../services/date-converter';
import { PAYMENT_INTERVAL_TO_SECONDS } from '../constants/date';
import { PaymentInterval } from './priceHelpers';

export const getSecondsFromPaymentInterval = (paymentInterval: string) => {
    return PAYMENT_INTERVAL_TO_SECONDS?.[paymentInterval as PaymentInterval];
};

export const subtractPaymentInterval = (date: string, paymentInterval: string) => {
    const seconds = getSecondsFromPaymentInterval(paymentInterval);
    return DateTime.fromISO(date).minus({ seconds });
};

export const addPaymentInterval = (date: string, paymentInterval: string) => {
    const seconds = getSecondsFromPaymentInterval(paymentInterval);
    return DateTime.fromISO(date).plus({ seconds });
};

export const getMilliseconds = {
    fromSeconds: (seconds: number) => seconds * 1000,
    fromMinutes: (minutes: number) => minutes * getMilliseconds.fromSeconds(60),
    fromHours: (hours: number) => hours * getMilliseconds.fromMinutes(60),
    fromDays: (days: number) => days * getMilliseconds.fromHours(24),
    fromMonths: (months: number) => months * getMilliseconds.fromDays(30)
};

export const dateIsInPast = (date: string) => {
    const dateTime = DateTime.fromISO(date);

    return dateTime < DateTime.now();
};

export const getLocalDateString = (date: string) => {
    const locale = i18next.language;
    const dateTime = DateTime.fromISO(date);
    return dateTime.toLocaleString(DateTime.DATE_FULL, { locale });
};

export const formatDate = (date: string | DateTime) => {
    const dateObj = date instanceof DateTime ? date : DateTime.fromISO(date);
    if (dateObj.toString() === 'Invalid Date') return 'N/A';
    return toLocal(dateObj.toJSDate());
};

export const isSevenDaysBefore = (date: string | undefined | null) => {
    const dateTime = DateTime.fromISO(date ?? '');
    if (!dateTime.isValid) return false;
    return dateTime.minus({ days: 7 }).toMillis() > Date.now();
};

export const getParsedDateString = (dateString: string) => {
    const [day, month, year] = dateString.split('-').map(Number);
    const parsedDate = new Date(year, month - 1, day); // Month is 0-based in JS
    if (isNaN(parsedDate.getTime())) {
        console.warn(`Invalid date format: ${parsedDate}`);
    }
    return parsedDate;
};

export const getDayAndMonth = (dateString: string, withYear: boolean) => {
    const date = DateTime.fromFormat(dateString, 'dd-MM-yyyy');
    return date.toFormat(withYear ? 'dd-MM-yyyy' : 'dd-MM');
};

export const getWeekdayFromString = (dateString: string) => {
    const date = DateTime.fromFormat(dateString, 'dd-MM-yyyy');
    const locale = i18next.language;
    return date.setLocale(locale).weekdayLong;
};

export const getDateWithMonthShortName = (dateString: string) => {
    const locale = i18next.language;
    const dateTime = DateTime.fromISO(dateString);
    const monthShortName = dateTime.setLocale(locale).toLocaleString({ month: 'short' });
    return `${dateTime.day} ${monthShortName} ${dateTime.year}`;
};

export const isDateInPeriod = (startDate: string, endDate: string, date?: string): boolean => {
    const dateToCompare = date ? DateTime.fromISO(date) : DateTime.now();
    const start = DateTime.fromISO(startDate);
    const end = DateTime.fromISO(endDate);

    return dateToCompare >= start && dateToCompare <= end;
};

export const asGymManagerDateTimeString = (date: Date) =>
    DateTime.fromJSDate(date).setZone('Europe/Amsterdam').toISO({ includeOffset: false });

export const areDatesEqual = (dateOne: string, dateTwo: string) => {
    if (dateOne === '' || dateTwo === '') return false;

    const parsedDateOne = DateTime.fromFormat(dateOne, 'dd-MM-yyyy');
    const parsedDateTwo = DateTime.fromFormat(dateTwo, 'dd-MM-yyyy');

    if (!parsedDateOne.isValid || !parsedDateTwo.isValid) {
        return false;
    }

    return parsedDateOne.toISODate() === parsedDateTwo.toISODate();
};
