import React, { ElementType, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { MemberOnlyProvider, MemberProvider } from '@src/services/member/MemberProvider';
import { isApp } from '@src/utils/helpers/window';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useCleanUrl } from '@src/utils/hooks/useCleanUrl';

import { DashboardRouter } from './Dashboard.router';
import { PublicRouter } from './Public.router';

export interface RouteProps {
    path: string;
    Component: React.ComponentType;
    Layout?: ElementType;
}

/**
 * Check which member provider should be used (based on route)
 */
const MemberDashboard = () => {
    const location = useLocation();
    const memberOnlyRoutes = ['friends', 'payments'];
    const [fetchAll, setFetchAll] = useState<boolean | null>(null);

    useEffect(() => {
        const memberOnly = memberOnlyRoutes.some((route) => location.pathname.includes(route));
        setFetchAll(!memberOnly);
    }, []);

    if (typeof fetchAll === 'boolean') {
        return fetchAll ? (
            <MemberProvider>
                <DashboardRouter />
            </MemberProvider>
        ) : (
            <MemberOnlyProvider>
                <DashboardRouter />
            </MemberOnlyProvider>
        );
    }
    return null;
};

export const AppRouter = (): JSX.Element => {
    const dispatch = useDispatch();
    const { data: isAuth } = useFetch<boolean>('/authentication/is-auth');

    useCleanUrl();

    useEffect(() => {
        if (!isApp()) return;

        dispatch({
            type: 'IS_APP_VIEW',
            payload: true
        });
    }, []);

    return (
        <>
            <Routes>
                {isAuth ? (
                    <Route path="*" element={<MemberDashboard />} />
                ) : (
                    <Route path="*" element={<PublicRouter />} />
                )}
            </Routes>
        </>
    );
};
