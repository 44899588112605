import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { InformationBlock } from '@src/components/InformationBlock/InformationBlock';
import { Section } from '@src/components/Layout/Section';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { useFiscalCode } from '@src/pages/MyInformation/ChangeFiscalCode/useFiscalCode.hook';

export const FiscalCodeSection = () => {
    const { t } = useTranslation();
    const { canChangeFiscalCode, isFiscalCodeValid } = useFiscalCode();

    if (!canChangeFiscalCode || isFiscalCodeValid) return null;

    return (
        <Wrapper>
            <Section>
                <InformationBlock data-testid="fiscal-code-section">
                    {t('payment.banner.fiscalcode.description')}
                    <div>
                        <ButtonLink
                            to="/information/change-fiscal-code"
                            data-testid="fiscal-code-button"
                        >
                            {t('payment.banner.fiscalcode.button')}
                        </ButtonLink>
                    </div>
                </InformationBlock>
            </Section>
        </Wrapper>
    );
};
