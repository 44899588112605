import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { pushToDataLayer } from '@src/services/utilities/analytics.service';

export const Service = (): JSX.Element => {
    const { t } = useTranslation();
    const ranOnce = useRef(false);

    const seamlyWrapperRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const parentElement = seamlyWrapperRef.current;
        if (!parentElement) {
            return;
        }

        window.seamlyFaqTree = window.seamlyFaqTree || [];
        const { locale: contextLocale = 'en-US' } = window.__MEMBER_CONTEXT__;
        const locale = contextLocale?.includes('en') ? 'en-en' : contextLocale.toLowerCase();

        window.seamlyFaqTree.push({
            action: 'init',
            args: {
                parentElement,
                locale
            }
        });
    }, [seamlyWrapperRef]);

    useEffect(() => {
        if (ranOnce.current === false) {
            ranOnce.current = true;
            pushToDataLayer({ event: 'custom_page_view', page_name: 'service page' });
        }
    }, []);

    return (
        <>
            <Helmet>
                <script src={process.env.REACT_APP_SEAMLY_ENDPOINT} />
            </Helmet>
            {/* HEADER */}
            <Header
                title={t('32')}
                text={t('179')}
                image={
                    <LineSVG id="white-vertical-lines" height={267} className="hidden xl:block" />
                }
                testid="service"
            />

            <Section>
                <div ref={seamlyWrapperRef} className="seamly-faq-placeholder p-m md:p-0" />
            </Section>
        </>
    );
};
