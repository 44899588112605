import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text, textSizes } from '@basic-fit/design-system';
import { Membership } from '@src/pages/ChangeMembership/ChangeMembershipFlow.types';
import { BestChoiceRibbon } from '@src/pages/ChangeMembership/components/BestChoiceRibbon';
import { SmartBikeModal } from '@src/pages/ChangeMembership/components/SmartBikeModal';
import { useMember } from '@src/services/member/MemberProvider';
import { getMembershipUspByCountry } from '@src/utils/helpers/uspHelpers';
import { useId } from '@src/utils/hooks/useId';

import { FormattedUSP } from './FormattedUSP';
import { Price } from './Price';

type MembershipInfoCardProps = {
    highlight?: boolean;
    active?: boolean;
    membership: Membership;
    price: number;
    description?: string | null;
    pricePeriod: string;
    onSelect: () => void;
};

export const MembershipInfoCard = ({
    highlight = false,
    active = false,
    membership,
    price,
    description,
    pricePeriod,
    onSelect
}: MembershipInfoCardProps) => {
    const { t } = useTranslation();
    const titleId = `selection-title-${useId()}`;
    const descriptionId = `selection-description-${useId()}`;
    const [isFocused, setIsFocused] = useState(false);
    const { state: member } = useMember();
    const { membership_type_g: currentMembership, mailing_country: country } = member || {};

    /**
     * There is only 1 info button required on bullet points
     * For now just trigger the action (bike modal) for this info
     */
    const [openBikeModal, setOpenBikeModal] = useState(false);

    const handleBulletAction = (label: string) => {
        if (label === 'usp.smart.bike') {
            setOpenBikeModal(true);
        }
    };

    /**
     * Based on the current membership, determine what subscription to highlight
     */
    const isHighlight = (membership: Membership) =>
        (currentMembership === Membership.BASIC && membership === Membership.PREMIUM) ||
        (currentMembership === Membership.COMFORT && membership === Membership.PREMIUM) ||
        (currentMembership === Membership.PREMIUM && membership === Membership.ALLIN) ||
        (currentMembership === Membership.PREMIUM && membership === Membership.ULTIMATE) ||
        (currentMembership === Membership.ALLIN && membership === Membership.ULTIMATE);

    return (
        <>
            <label
                className={`relative w-full grid grid-cols-1 grid-rows-[95px_1fr] cursor-pointer h-auto ${
                    highlight ? 'bg-gradient-orange' : 'bg-jersey-grey'
                }`}
                data-testid={`membership-card-${membership}`}
            >
                <input
                    value={membership}
                    checked={active}
                    type="radio"
                    // Provide a onChange handler to prevent react from rendering a read-only field
                    // Checked state is controlled by the parent component
                    onChange={(e) => e.preventDefault()}
                    onClick={onSelect}
                    className={`sr-only peer`}
                    aria-labelledby={titleId}
                    aria-describedby={descriptionId}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
                {/* Title Header */}
                <div
                    id={titleId}
                    className={`flex items-center mx-m peer-disabled:text-nav-inactive ${
                        highlight ? 'text-white' : 'text-anthracite'
                    }`}
                >
                    <Heading size={6} className="basis-2/3 grow text-inherit">
                        {t('membership.change.card.title').replace('%%membership%%', membership)}
                    </Heading>
                    {isHighlight(membership) && <BestChoiceRibbon />}
                </div>
                {/* Content Area */}
                <div
                    className="m-xxxs p-s bg-white space-y-[16px] peer-disabled:text-nav-inactive"
                    id={descriptionId}
                >
                    <div>
                        {description ? (
                            <>{description}</>
                        ) : (
                            <>
                                {getMembershipUspByCountry(membership, country, true).map(
                                    (item) => (
                                        <FormattedUSP
                                            action={() => handleBulletAction(item)}
                                            country={country}
                                            id={item}
                                            info={item === 'usp.smart.bike'}
                                            key={item}
                                            size={2}
                                        />
                                    )
                                )}
                            </>
                        )}
                        {membership === Membership.ALLIN ? (
                            <>
                                <Text size={2} className="mt-m text-grey-4">
                                    {t('1914')}
                                </Text>
                                <Price
                                    amount={price}
                                    caption={`/ ${t('per4Weeks')}`}
                                    className="mt-xxxs"
                                />
                                <Text size={2} className="mt-xxs mb-m text-grey-4">
                                    {t('membership.allin.shipment')}
                                </Text>
                            </>
                        ) : (
                            <Price amount={price} caption={`/ ${pricePeriod}`} className="mt-m" />
                        )}
                    </div>
                    <div className="flex items-center justify-start">
                        {/* Radio Indicator */}
                        <div
                            role="presentation"
                            className={`appearance-none bg-clip-content rounded-full w-[18px] h-[18px] p-[2px] box-border border-2 transition-all duration-300 hover:border-anthracite-58 border-anthracite ${
                                isFocused
                                    ? ' ring-mint ring-offset-1 ring-2 outline-none border-anthracite border-2'
                                    : ''
                            } ${active ? 'bg-orange' : ''}`}
                        />
                        {/* Radio Label */}
                        <span
                            // left margin is to align the label with the radio button 18px (button size) + 10px (spacing)
                            className={`ml-[10px] ${textSizes[1]} font-heading-treble font-extrabold !text-[16px] uppercase`}
                        >
                            {`${t('membership.button.want').replace('%%membership%%', membership)}`}
                        </span>
                    </div>
                </div>
            </label>
            {openBikeModal && (
                <SmartBikeModal isOpen={openBikeModal} onClose={() => setOpenBikeModal(false)} />
            )}
        </>
    );
};
