import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';

export enum FlowTokenType {
    'changeEmailToken' = 'changeEmailToken',
    'changeIbanToken' = 'changeIbanToken',
    'changePhoneNumberToken' = 'changePhoneNumberToken',
    'changeFiscalCodeToken' = 'changeFiscalCodeToken'
}

export const enum ModalState {
    RESEND = 'RESEND',
    TOO_MANY_TRIES = 'TOO_MANY_TRIES',
    SUCCESS = 'SUCCESS'
}

type RaisedEvent = { data: { message: string } };

export function isRateLimitExceeded(_ctx: unknown, event: RaisedEvent): boolean {
    return event.data.message === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_RATE_LIMIT_EXCEEDED;
}
