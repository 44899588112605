import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { Path } from '@src/router/CancelMembership.router';
import { pushToDataLayer } from '@src/services/utilities/analytics.service';
import { isMobile } from '@src/utils/helpers/device';

type DowngradeModalProps = {
    isOpen: boolean;
    onClose(): void;
};

export const DowngradeModal = ({ isOpen, onClose }: DowngradeModalProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isEventPushed = useRef(false);

    if (isOpen === false) return null;

    if (isEventPushed.current === false) {
        // Ensure that the event is only pushed once
        isEventPushed.current = true;
        pushToDataLayer({ event: 'custom_page_view', page_name: 'other solution modal' });
    }

    return (
        <Modal
            isOpen={isOpen}
            onModalClose={onClose}
            data-testid="cancel-membership-reasons-downgrade-modal"
        >
            <Heading size={isMobile() ? 2 : 5} className="mb-2">
                {t('membership.cancellation.downgrade_modal_title')}
            </Heading>
            <div className="flex flex-col gap-4">
                <Text size={2}>{t('membership.cancellation.downgrade_modal_text')}</Text>
                <Button
                    text={t('1625')}
                    onClick={() => {
                        pushToDataLayer({
                            event: 'custom_click',
                            click_type: 'change membership',
                            eventCallback: () => navigate('/change-membership')
                        });
                    }}
                    data-testid="cancel-membership-reasons-downgrade-modal-change-membership"
                />
                <Button
                    color="naked"
                    text={t('membership.cancellation.proceed')}
                    onClick={() => {
                        pushToDataLayer({
                            event: 'custom_click',
                            click_type: 'proceed to cancel',
                            eventCallback: () => {
                                onClose();
                                navigate(Path.Base + Path.Check);
                            }
                        });
                    }}
                    data-testid="cancel-membership-reasons-downgrade-modal-proceed"
                />
            </div>
        </Modal>
    );
};
