import { domain, headers } from '@src/services/data';
import { XSTATE_STATECHART_DEBUG } from '@src/services/utilities';
import { createActorContext } from '@xstate/react';
import axios from 'axios';
import { assign, createMachine } from 'xstate';

import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';
import { FlowTokenType, ModalState } from '../machine-helpers';

type Context = {
    flow: FlowTokenType;
    fiscalcode: string;
    error: string | null;
    modal: ModalState | null;
};

type Events =
    | { type: 'SUBMIT_FISCAL_CODE'; fiscalcode: string }
    | { type: 'OPEN_MODAL'; modal: ModalState }
    | { type: 'CLOSE_MODAL' };

type States =
    | { value: 'pending'; context: Context }
    | { value: 'error'; context: Context }
    | { value: 'submitting'; context: Context }
    | { value: 'success'; context: Context };

const changeFiscalCodeMachine = createMachine<Context, Events, States>({
    id: 'changeFiscalCode',
    preserveActionOrder: true,
    predictableActionArguments: true,
    context: {
        flow: FlowTokenType.changeFiscalCodeToken,
        fiscalcode: '',
        error: '',
        modal: null
    },
    initial: 'pending',
    states: {
        pending: {},
        error: {},
        submitting: {
            invoke: {
                src: async (ctx) => {
                    const { data } = await axios({
                        method: 'POST',
                        url: `${domain}/member/update-member-fiscal-code`,
                        headers,
                        data: {
                            fiscalCode: ctx.fiscalcode,
                            emailTokenType: ctx.flow
                        }
                    });

                    return data;
                },
                onDone: {
                    target: 'success',
                    actions: assign({
                        fiscalcode: (_, evt) => evt.data.fiscalCode,
                        error: null
                    })
                },
                onError: {
                    target: 'error',
                    actions: assign({
                        error: (_, evt) => {
                            if (!axios.isAxiosError(evt.data)) {
                                return CHANGE_MEMBER_ERROR_MESSAGE_MAP.UNEXPECTED_ERROR;
                            }

                            const message = evt.data.response?.data?.message;
                            if (message in CHANGE_MEMBER_ERROR_MESSAGE_MAP) {
                                return message;
                            }

                            return CHANGE_MEMBER_ERROR_MESSAGE_MAP.UNEXPECTED_ERROR;
                        }
                    })
                }
            }
        },
        success: { type: 'final' }
    },

    on: {
        SUBMIT_FISCAL_CODE: {
            target: 'submitting',
            actions: assign({
                fiscalcode: (_, evt) => evt.fiscalcode
            })
        },
        OPEN_MODAL: {
            actions: assign({
                modal: (_, evt) => evt.modal
            })
        },
        CLOSE_MODAL: {
            actions: assign({
                modal: null
            })
        }
    }
});

export const ChangeFiscalCodeContext = createActorContext(changeFiscalCodeMachine, {
    devTools: XSTATE_STATECHART_DEBUG === 'true'
});
