import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMember } from '@src/services/member/MemberProvider';
import { getFromSessionCache } from '@src/services/utilities/sessionCache.service';

export const useFiscalCode = () => {
    const { state: member } = useMember();

    const canChangeFiscalCode =
        member.home_club_s?.billingCountry?.toLowerCase() ||
        member.mailing_country?.toLowerCase() === 'spain';
    const fiscalCode = member?.fiscal_code || '';
    const isFiscalCodeValid = member?.is_fiscal_code_valid || false;

    const showFiscalCodeModalPreference = ['true', null].includes(
        getFromSessionCache('showFiscalCodeModalPreference')
    );

    return {
        canChangeFiscalCode,
        fiscalCode,
        isFiscalCodeValid,
        showFiscalCodeModalPreference
    };
};

export const useCanChangeFiscalCodeGuard = () => {
    const navigate = useNavigate();
    const { canChangeFiscalCode } = useFiscalCode();

    useEffect(() => {
        if (canChangeFiscalCode) return;

        navigate('/overview');
    }, [canChangeFiscalCode, navigate]);
};
