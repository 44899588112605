import { DebitsProps, NoteArticle } from '@src/utils/hooks/api/types/debits';
import { DebtTransaction, LogTransaction } from '@src/utils/hooks/api/types/transactions';
import { DateTime } from 'luxon';

const codes = {
    debt: ['sta', 'ab', 'rfe'],
    paid: ['bpm', 'pay', 'dd', 'pca'],
    creditNote: ['cb', 'dwo'],
    rejection: ['rej']
};

/**
 * Sort current transactions log and group by date
 * Using debt transactions will give us a precise date (with time) for each transaction
 * When there is more than 1 transaction per day, we can show the correct chronological order
 * @param logs /payments/get-transaction-log
 * @param debts /payments/debt-transactions
 */
export const sortTransactions = (logs: LogTransaction[], debts: DebtTransaction[]) => {
    const transactions = [...Array.from(new Set(Object.values(logs)))];

    // get unique dates
    const dates = [...Array.from(new Set(Object.values(logs).map((values) => values.date)))];

    // group transactions by date
    const groups = dates.map((date) => transactions.filter((item) => item.date === date));

    // group transactions by code groups
    groups.forEach((entries, index) => {
        if (entries.length > 1) {
            Object.values(codes).forEach((values) => {
                groups.push(entries.filter((log) => values.includes(log.code)));
            });
            groups.splice(index, 1);
        }
    });

    const sorted = groups
        .filter((val) => val && val.length > 0)
        .map((group) => mapDebtDate(group, debts))
        .sort((a, b) => new Date(b[0].date).getTime() - new Date(a[0].date).getTime());

    return sorted;
};

/**
 * Get precise date for this transaction by checking the debt transaction
 * Otherwise, use the date from the log transaction
 */
const mapDebtDate = (group: LogTransaction[], debts: DebtTransaction[]) => {
    return group
        .map((item) => {
            const match = debts.find(
                (debt) => Math.abs(debt.amount) === item.amount && debt.comment === item.description
            );
            item.date = match?.dateTime || item.date;
            return item;
        })
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
};

/**
 * Map transactions to a format that is used in the UI components
 * - Start with the oldest transaction
 * - Calculate the balance on each transaction
 * - Reverse it back for chronological order
 */

export type TransactionProps = {
    balance: number;
    date: string;
    item: LogTransaction[];
    id: string;
    description: string;
    amount: number;
    show: boolean;
    transaction: number;
    type?: string;
};

export const mapTransactions = (transactions: LogTransaction[][]): TransactionProps[] => {
    const balance: number[] = [];
    return transactions
        .reverse()
        .map((logs) => {
            const total = logs.reduce((acc, log) => acc + log.amount, 0);
            const type = Object.entries(codes).find(([key, values]) => {
                if (values.includes(logs[0].code)) {
                    return key;
                }
            });

            balance.push(total); // keep history
            const currentBalance = balance.reduce((acc, val) => acc + val, 0);

            return {
                balance: Number(currentBalance.toFixed(2)),
                id: logs[0].id,
                date: DateTime.fromISO(logs[0].date).toFormat('dd-MM-yyyy'),
                amount: logs[0].amount,
                item: logs,
                show: false,
                description: logs[0].description,
                transaction: total,
                type: type?.[0],
                redFlag: setFlag(balance) && type?.[0] !== 'paid'
            };
        })
        .reverse();
};

const setFlag = (balance: number[]) => {
    const flagAfter = 2;
    const balanceHistory = balance.slice(balance.length - flagAfter);
    return balanceHistory.length >= flagAfter && balanceHistory.every((val) => val < 0);
};

export const filterDebits = (transactions: TransactionProps[]) => {
    // get latest credit and debit transactions
    const debit = transactions.find((transaction) => transaction.type === 'debt');
    const credit = transactions.find((transaction) => transaction.type === 'paid');

    if (debit && credit) {
        const debitDate = DateTime.fromFormat(debit.date, 'dd-MM-yyyy');
        const creditDate = DateTime.fromFormat(credit.date, 'dd-MM-yyyy');

        // Is the recent debit transaction in the past?
        // And check most recent credit transaction on specific weekday
        const hasDebitAfterCredit =
            debitDate.diff(creditDate, 'days').days < 0 &&
            (creditDate.weekday === 1 || // credit = monday, debit = tuesday
                creditDate.weekday === 3 || // credit = wednesday, debit = thursday
                creditDate.weekday === 5); // credit = friday, debit = saturday or sunday

        // Remove credit transaction (positive balance) because we have no debit transaction yet
        if (hasDebitAfterCredit && transactions[0].balance > 0) {
            return transactions.filter((transaction) => transaction !== credit);
        }
    }
    return transactions;
};

/**
 * Converts an object of upcoming fees into a sorted array of fee records,
 * where each record contains a date and its associated fees,
 * ensuring the dates are sorted in ascending order based on the given dateFormat (defaulting to 'dd-MM-yyyy').
 * @param upcomingFees
 * @param dateFormat
 * @returns UpcomingFees array of objects containing date and fees
 */
export function transformUpcomingFees(
    upcomingFees: Record<string, NoteArticle[]>,
    dateFormat = 'dd-MM-yyyy'
): UpcomingFee[] {
    return Object.entries(upcomingFees)
        .map(([date, fees]) => ({
            date,
            fees
        }))
        .sort((a, b) => {
            return (
                DateTime.fromFormat(a.date, dateFormat).toMillis() -
                DateTime.fromFormat(b.date, dateFormat).toMillis()
            );
        });
}

/**
 * Find first valid debit period start date and return the date with corresponding fees
 * @param debits
 * @returns UpcomingFee object containing date and fees or null
 */
export function findFirstValidDateRecord(debits: DebitsProps): UpcomingFee | null {
    if (!debits || !debits?.startDate || !debits?.endDate) return null;

    const start = DateTime.fromFormat(debits.startDate, 'dd-MM-yyyy');
    const end = DateTime.fromFormat(debits.endDate, 'dd-MM-yyyy');

    // Get all upcoming fee dates as objects [{ date: "dd-MM-yyyy", fees: [...] }]
    const upcomingDateRecords = Object.entries(debits.upcomingFees)
        .map(([date, fees]) => ({
            date: DateTime.fromFormat(date, 'dd-MM-yyyy'),
            originalDate: date, // Keep the original string format
            fees
        }))
        .filter(({ date }) => date >= start && date <= end) // Keep only records in range
        .sort((a, b) => a.date.toMillis() - b.date.toMillis()); // Sort ascending

    if (!upcomingDateRecords.length) return null;

    return { date: upcomingDateRecords[0].originalDate, fees: upcomingDateRecords[0].fees };
}

type UpcomingFee = {
    date: string;
    fees: NoteArticle[];
};
