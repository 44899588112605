import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@basic-fit/design-system';
import { Form } from '@src/components/Form/Form';
import { TextInput } from '@src/components/Form/TextInput/TextInput';
import { TooManyTriesModal } from '@src/feature/ChangeMemberModals/TooManyTries.modal';

import googleTagManagerPush from 'services/google-tag-manager-push';
import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';
import { ModalState } from '../machine-helpers';
import { ChangeEmailContext } from './changeEmail.machine';

export const VerifyPassword = () => {
    const { t } = useTranslation();
    const [state, send] = ChangeEmailContext.useActor();
    const effectRan = useRef(false);

    useEffect(() => {
        if (effectRan.current) return;
        effectRan.current = true;
        googleTagManagerPush.viewPageEvent('verification step');
    }, []);

    const shouldShowTooManyTries = ChangeEmailContext.useSelector((state) => {
        return state.context.modal === ModalState.TOO_MANY_TRIES;
    });

    const shouldShowPasswordError = ChangeEmailContext.useSelector((state) => {
        if (!state.context.passwordError) return undefined;
        if (!state.matches('password.error')) return undefined;
        return true;
    });

    return (
        <div data-cy="change-email-password" data-testid="change-email-password">
            <Form
                onSubmit={({ password }) => send({ type: 'SUBMIT_PASSWORD', password })}
                name="email"
                className="space-y-3 pt-6"
            >
                <TextInput
                    required
                    type="password"
                    autoComplete="current-password"
                    name="password"
                    label={t('details.change.current.password')}
                    error={shouldShowPasswordError && <PasswordError />}
                    defaultValue={state.context.password}
                    onInput={() => send('EDITING')}
                    data-cy="password"
                    data-testid="password"
                />
                <Button
                    fullwidth
                    type="submit"
                    loading={state.matches('password.verifying')}
                    disabled={state.matches('password.verifying')}
                    data-cy="submit-password"
                    data-testid="submit-password"
                    onClick={() => googleTagManagerPush.clickEvent('confirm password')}
                >
                    {t('128')}
                </Button>
                <Button
                    fullwidth
                    theme="light"
                    type="button"
                    onClick={() => send('BACK')}
                    className="!bg-white hover:bg-white !text-anthracite"
                    data-cy="back"
                    data-testid="back"
                >
                    {t('162')}
                </Button>
            </Form>
            {shouldShowTooManyTries && (
                <TooManyTriesModal
                    onCloseCb={() => {
                        send('CLOSE_MODAL');
                    }}
                    onOpen={() => googleTagManagerPush.viewPageEvent('too many tries modal')}
                />
            )}
        </div>
    );
};

const passwordErrorTranslations = {
    [CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_PASSWORD_INVALID]:
        'details.change.email.iban.invalidPassword',
    [CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_PASSWORD_NO_MATCH]:
        'details.change.email.iban.invalidPassword',
    [CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_RATE_LIMIT_EXCEEDED]:
        'details.change.email.iban.tooManyAttempts',
    FALLBACK: 'details.change.email.iban.unexpectedError'
};

const PasswordError = () => {
    const { t } = useTranslation();
    const [state] = ChangeEmailContext.useActor();

    if (!state.context.passwordError) return null;
    if (!state.matches('password.error')) return null;

    const error = state.context.passwordError as keyof typeof passwordErrorTranslations;
    const passwordErrorTranslationKey =
        passwordErrorTranslations[error] || passwordErrorTranslations.FALLBACK;

    return (
        <>
            {t(passwordErrorTranslationKey)}
            <CustomerSupportLink />
        </>
    );
};

const CustomerSupportLink = () => {
    const { t } = useTranslation();
    return (
        <>
            &nbsp;
            <a
                className="underline text-red"
                href={t('customer.support.url')}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="contact customer support"
                onClick={() => googleTagManagerPush.clickEvent('to CS')}
            >
                {t('membership.cancellation.contact_support')}
            </a>
        </>
    );
};
