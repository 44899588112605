import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Outline, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Icon } from '@src/components/Icons/Icons';
import { Modal } from '@src/components/Modals/Modal';
import { headers } from '@src/utils/hooks/fetchOptions';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import { useSWRConfig } from 'swr';

type ModalProps = {
    isOpen: boolean;
    swapCountLeft?: 0 | 1 | 2;
    nextSwapDate?: string;
    shouldShowWarning?: boolean;
    onClose?: () => void;
};

export const RemoveDeviceModal = ({
    isOpen,
    swapCountLeft = 1,
    nextSwapDate,
    shouldShowWarning,
    onClose
}: ModalProps) => {
    const { t } = useTranslation();

    const { awaiting: isLoading, awaitSpin } = useAwaitSpinner();
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState<boolean>();

    const { mutate } = useSWRConfig();

    const handleModalClose = () => {
        onClose?.();
    };

    const removeDevice = async () => {
        setDisabled(true);

        try {
            await awaitSpin(async () => {
                const response = await fetch('/member/remove-device', {
                    method: 'POST',
                    headers: headers
                });

                setSuccess(response.ok);
            });

            await mutate('/member/get-device-info');
            await mutate('/member/validate-device-swap');

            // send success callback to app
            const payload = JSON.stringify({ event: 'removed_device' });
            window.ReactNativeWebView?.postMessage(payload);
        } catch {
            setSuccess(false);
        } finally {
            setDisabled(false);
        }
    };

    const getDataCy = () => {
        switch (success) {
            case true:
                return 'qr-remove-device-successful';
            case false:
                return 'remove-device-error';
            default:
                return 'qr-remove-device-confirm';
        }
    };

    return (
        <Modal isOpen={isOpen} onModalClose={handleModalClose}>
            <Heading size={4} className="mb-s">
                {t('access-change.remove-modal.title').split(' ').slice(0, 2).join(' ')}{' '}
                <Outline>
                    {t('access-change.remove-modal.title').split(' ').slice(-1).join(' ')}
                </Outline>
            </Heading>
            <Text size={2}>{t('access-change.remove-modal.body')}</Text>
            {shouldShowWarning && nextSwapDate && (
                <div className="flex text-status-red mt-xs gap-xxs">
                    <Icon id={'info-outline'} className="transform-none h-m flex items-center" />
                    <Text size={2}>
                        {t(`access-change.remove-modal.warning-${swapCountLeft}`)?.replace(
                            '{date}',
                            nextSwapDate
                        )}
                    </Text>
                </div>
            )}
            <Button
                className="mt-m"
                text={t('access-change.remove-modal.confirm')}
                onClick={removeDevice}
                loading={isLoading}
                disabled={disabled || success}
                icon={success ? 'tick' : ''}
                data-cy={getDataCy()}
                data-testid={getDataCy()}
            />
            <Button
                className="mt-m"
                text={t('access-change.remove-modal.go-back')}
                onClick={handleModalClose}
                disabled={disabled}
                color="naked"
            />
        </Modal>
    );
};
