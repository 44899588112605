import React, { useContext, useEffect, useReducer } from 'react';
import { isSecondary } from '@src/utils/helpers/memberHelpers';
import type { BalanceSheetProps } from '@src/utils/hooks/api/types/balanceSheet';
import type { ChosenCompensationsProps } from '@src/utils/hooks/api/types/chosenCompensations';
import type { CompensationOptionsProps } from '@src/utils/hooks/api/types/compensationOptions';
import type { DebitsProps } from '@src/utils/hooks/api/types/debits';
import type { VisitsResponse } from '@src/utils/hooks/api/types/visits';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import type { FriendsDataProps } from '../../../types/friends.types';
import type { MemberDataProps, PeopleMembership } from '../../../types/member.types';
import { memberReducer } from './MemberReducer';

type MemberContextProps = {
    state: any;
    isLoaded: boolean;
    hasError: boolean;
};

export const MemberContext = React.createContext<MemberContextProps>(null as any);

export const useMember = () => useContext(MemberContext);

export const MemberProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(memberReducer, {});

    /**
     * https://swr.vercel.app/docs/conditional-fetching#dependent
     */
    const member = useFetch<MemberDataProps>('/member/get-member');
    const visits = useFetch<VisitsResponse>('/member/visits');
    const getPeopleMembershipByPeopleId = useFetch<PeopleMembership>(
        '/member/get-people-membership-by-people-id'
    );

    const friends = useFetch<FriendsDataProps>(
        member.data?.is_friends_allowed ? '/friends/get-friends' : null
    );

    const balance = useFetch<BalanceSheetProps>(
        !isSecondary(member.data) ? '/payments/get-balance-sheet' : null
    );

    const compensations = useFetch<ChosenCompensationsProps>(
        !isSecondary(member.data) ? '/extra/get-chosen-compensations' : null
    );

    const campaign = useFetch<boolean>('/extra/is-club-in-active-compensation-campaign');

    const compensationOptions = useFetch<CompensationOptionsProps>(
        state.chosenCompensations?.length ? '/extra/get-compensation-options' : null
    );

    const debits = useFetch<DebitsProps>('/payments/v2/get-debits');
    const activeFetch = [
        member,
        friends,
        balance,
        visits,
        compensations,
        campaign,
        compensationOptions,
        debits,
        getPeopleMembershipByPeopleId
    ];

    /**
     * Handle fetched data in memberReducer
     */
    useEffect(() => {
        if (member.data && !member.isLoading) {
            dispatch({ type: 'get-member', payload: member.data });
            dispatch({ type: 'get-membership' });
            dispatch({ type: 'set-employee' });
        }
    }, [member.data]);

    useEffect(() => {
        if (debits.data && !debits.isLoading) {
            dispatch({ type: 'set-debits', payload: debits.data });
        }
    }, [debits.data]);

    useEffect(() => {
        if (friends.data && !friends.isLoading) {
            dispatch({ type: 'friends', payload: friends.data });
        }
    }, [friends.data]);

    useEffect(() => {
        if (balance.data && !balance.isLoading) {
            dispatch({ type: 'balance', payload: balance.data });
        }
    }, [balance.data]);

    useEffect(() => {
        if (compensations.data && !compensations.isLoading) {
            dispatch({ type: 'compensations', payload: compensations.data });
        }
    }, [compensations.data]);

    useEffect(() => {
        if (typeof campaign.data !== 'undefined' && !campaign.isLoading) {
            dispatch({ type: 'campaign', payload: campaign.data });
        }
    }, [campaign.data]);

    useEffect(() => {
        if (compensationOptions.data && !compensationOptions.isLoading) {
            dispatch({ type: 'compensationOptions', payload: compensationOptions.data });
        }
    }, [compensationOptions.data]);

    useEffect(() => {
        if (visits.data && !visits.isLoading) {
            dispatch({ type: 'visits', payload: visits.data });
        }
    }, [visits.data]);

    useEffect(() => {
        if (getPeopleMembershipByPeopleId.data && !getPeopleMembershipByPeopleId.isLoading) {
            dispatch({ type: 'people-membership', payload: getPeopleMembershipByPeopleId.data });
        }
    }, [getPeopleMembershipByPeopleId.data]);

    /**
     * Handle loading status
     */
    const isLoaded = activeFetch.every((fetch) => !fetch.isLoading);
    const hasError = activeFetch.some((fetch) => fetch.isError);

    return (
        <MemberContext.Provider value={{ state, isLoaded, hasError }}>
            {children}
        </MemberContext.Provider>
    );
};

export const MemberOnlyProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(memberReducer, {});

    const member = useFetch<MemberDataProps>('/member/get-member');
    const visits = useFetch<VisitsResponse>('/member/visits');

    const balance = useFetch<BalanceSheetProps>(
        !isSecondary(member.data) ? '/payments/get-balance-sheet' : null
    );

    const activeFetch = [member, visits, balance];

    /**
     * Handle fetched data in memberReducer
     */
    useEffect(() => {
        if (member.data && !member.isLoading) {
            dispatch({ type: 'get-member', payload: member.data });
            dispatch({ type: 'get-membership' });
            dispatch({ type: 'set-employee' });
        }
    }, [member.data]);

    useEffect(() => {
        if (visits.data && !visits.isLoading) {
            dispatch({ type: 'visits', payload: visits.data });
        }
    }, [visits.data]);

    useEffect(() => {
        if (balance.data && !balance.isLoading) {
            dispatch({ type: 'balance', payload: balance.data });
        }
    }, [balance.data]);

    /**
     * Handle loading status
     */
    const isLoaded = activeFetch.every((fetch) => !fetch.isLoading);
    const hasError = activeFetch.some((fetch) => fetch.isError);

    return (
        <MemberContext.Provider value={{ state, isLoaded, hasError }}>
            {children}
        </MemberContext.Provider>
    );
};
