import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@src/components/Buttons/Button';
import { PageHeading } from '@src/pages/ChangeMembership/components/PageHeading';
import { useSelector } from '@xstate/react';

import { Overview } from '../components/Overview';
import { usePaymentAmounts } from '../hooks/usePaymentsAmounts.hook';
import { ChangeMembershipContext } from '../machines/ChangeMembershipProvider';
import { renewSelector } from '../machines/changeMembershipSelectors';

export const MembershipOverview = () => {
    const { t } = useTranslation();
    const { changeService } = useContext(ChangeMembershipContext);

    const isFetching = useSelector(changeService, (state) => state.matches('save'));
    const membershipIsChanged = useSelector(changeService, (state) => state.matches('done'));

    const renew = useSelector(changeService, renewSelector);
    const { amountToPay } = usePaymentAmounts(renew);

    return (
        <>
            <PageHeading index={3}>{t('379')}</PageHeading>

            <Overview />

            <div className="px-xs pb-l m-auto w-2/3">
                <Button
                    className="px-4"
                    type="submit"
                    // This form attribute is used to submit the change membership form when the button is clicked
                    form="change-membership-form"
                    text={t(amountToPay > 0 ? '1668' : '385')}
                    loading={isFetching}
                    disabled={membershipIsChanged}
                    icon={membershipIsChanged ? 'tick' : ''}
                />
                <Button
                    text={t('162')}
                    color="naked"
                    className="w-auto m-auto"
                    data-cy="previous-step-button"
                    data-testid="previous-step-button"
                    onClick={() => changeService.send('PREVIOUS_STEP')}
                />
            </div>
        </>
    );
};
