import React, { ElementType } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Redirect } from '@src/components/Redirect';
import { RestrictedLayout } from '@src/layouts/Restricted.layout';
import { Advantage } from '@src/pages/Advantage/Advantage';
import { AllInRestrictedPage } from '@src/pages/AllIn/AllInRestricted.page';
import { ChangeMembershipFlow } from '@src/pages/ChangeMembership/ChangeMembershipFlow';
import { MemberGetMemberPage } from '@src/pages/MemberGetMember/MemberGetMember.page';
import { MemberGetMemberFriendPage } from '@src/pages/MemberGetMember/MemberGetMemberFriend.Page';
import { ChangeHomeClub } from '@src/pages/Membership/Membership/components/ChangeHomeClub/ChangeHomeClub';
import { FreezeMembershipPage } from '@src/pages/Membership/Membership/components/FreezeMembership/FreezeMembershipPage';
import { Membership } from '@src/pages/Membership/Membership/Membership';
import MarketingPreferences from '@src/pages/MyInformation/MarketingPreferences';
import { GracePeriodMemberOverviewPage } from '@src/pages/Overview/GracePeriodMemberOverview.page';
import { OverviewPage } from '@src/pages/Overview/Overview.page';
import { SecondaryMemberOverviewPage } from '@src/pages/Overview/SecondaryMemberOverview.page';
import { PaymentPage } from '@src/pages/Payments/Payment.page';
import { SecondaryMemberRegister } from '@src/pages/SecondaryMember/SecondaryMemberRegister.page';
import { Service } from '@src/pages/Service/Service.page';
import { VisitsPage } from '@src/pages/Visits/Visits.page';
import { CancelMembershipRouter } from '@src/router/CancelMembership.router';
import { FriendsRouter } from '@src/router/Friends.router';
import { MyInformationRouter } from '@src/router/MyInformation.router';
import { useMember } from '@src/services/member/MemberProvider';
import { canCancelMembership, canRenewMembership } from '@src/utils/helpers/memberHelpers';

import { MemberRoles } from 'services/memberRoles';

export type RouteProps = {
    path: string;
    Component: React.ComponentType;
    Layout?: ElementType;
};

/**
 * All available routes for main router
 */
export const routes: RouteProps[] = [
    { path: 'overview', Component: OverviewPage },
    // Keeping this route for backwards compatibility
    { path: 'gym-time-booking', Component: () => <Navigate to="/overview" /> },
    {
        path: 'cancel-membership/*',
        Component: () => {
            const { state: member } = useMember();

            if (canCancelMembership(member) === false) return <Navigate to="/membership" />;

            return <CancelMembershipRouter />;
        }
    },
    { path: 'change-membership/*', Component: ChangeMembershipFlow },
    { path: 'visits', Component: VisitsPage },
    { path: 'service', Component: Service },
    { path: 'advantage', Component: Advantage },
    { path: 'all-in-only', Component: AllInRestrictedPage, Layout: RestrictedLayout },
    { path: 'member-get-member', Component: MemberGetMemberPage },
    { path: 'member-get-member-friend', Component: MemberGetMemberFriendPage },
    { path: 'payments', Component: PaymentPage },
    { path: 'debt/:id/:token', Component: PaymentPage },
    { path: 'information/*', Component: MyInformationRouter },
    { path: 'membership', Component: Membership },
    { path: 'membership/freeze', Component: FreezeMembershipPage },
    { path: 'membership/changed', Component: Membership },
    { path: 'membership/renewed', Component: Membership },
    { path: 'membership/change-home-club', Component: ChangeHomeClub },
    { path: 'friends/*', Component: FriendsRouter },
    {
        path: 'renew-membership/*',
        Component: () => {
            const { state: member } = useMember();

            if (!canRenewMembership(member)) return <Navigate to={`/membership`} />;

            return <ChangeMembershipFlow renew={true} />;
        }
    },
    {
        path: 'friend-confirmation/:token',
        Component: () => {
            const { token } = useParams();
            return <Navigate to={`/friends/confirmation/${token}`} />;
        }
    },
    {
        path: 'friend-registered-successfully',
        Component: () => <Navigate to="/friends/register/success" />
    },
    {
        path: 'friend-registered-link-expired',
        Component: () => <Navigate to="/friends/register/expired" />
    },
    {
        path: 'secondary-member/register',
        Component: SecondaryMemberRegister
    },
    {
        path: 'gxr',
        Component: () => <Redirect to="/gxr" />
    }
];

const secondaryMemberRoutesAllowed = ['service', 'gxr', 'gym-time-booking'];
const employeeRoutesNotAllowed = [
    'change-membership/*',
    'renew-membership/*',
    'all-in',
    'friends/*',
    'cancel-membership',
    'payments',
    'member-get-member',
    'member-get-member-friend'
];

/**
 * Returns routes that are accessible for given member role
 * - Add custom routes with components based on member role
 * - Filter out routes that are not accessible for given member role
 */
export const getMemberRoutes = (role: MemberRoles) => {
    switch (role) {
        case MemberRoles.GRACE_PERIOD:
            // Only include routes that are allowed for grace period members
            return [
                ...routes.filter(({ path }) => path === 'payments'),
                { path: 'overview', Component: GracePeriodMemberOverviewPage },
                {
                    path: '/information/marketing-preferences',
                    Component: () => <MarketingPreferences redirectOnChange="/overview" />
                }
            ];
        case MemberRoles.SECONDARY:
            // Only include routes that are allowed for secondary members
            return [
                ...routes.filter(({ path }) => secondaryMemberRoutesAllowed.includes(path)),
                { path: 'overview', Component: SecondaryMemberOverviewPage },
                {
                    path: '/information/marketing-preferences',
                    Component: () => <MarketingPreferences redirectOnChange="/overview" />
                }
            ];
        case MemberRoles.EMPLOYEE:
            // exclude routes that are not accessible for employees
            return routes.filter(({ path }) => employeeRoutesNotAllowed.includes(path) === false);
        case MemberRoles.ALL_IN_ONLY:
            return routes.filter(({ path }) => path === 'all-in-only');
        case MemberRoles.PRIMARY:
        // Fallthrough to default
        default:
            return routes;
    }
};
