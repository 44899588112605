import React from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from '@src/components/Logo/Logo';
import { findFirstValidDateRecord } from '@src/pages/Payments/utils/transactionHelpers';
import { getMemberName } from '@src/utils/helpers/memberHelpers';
import { getNumber, getPrice } from '@src/utils/helpers/priceHelpers';
import { DebitsProps } from '@src/utils/hooks/api/types/debits';

type PaymentDocumentProps = {
    member: {
        id: string;
        gender_s: string;
        firstname: string;
        middle_name: string;
        last_name: string;
        mailing_street: string;
        mailing_house_number: string;
        poste_code: string;
        mailing_city: string;
        home_club: string;
        membership_number_s: string;
        membership_name_g: string;
        id_g: string;
    };
    debits: DebitsProps;
};

// User should be able to download a note document as a PDF which contains information
// about the upcoming fees.
// In case of lower entry barrier, the '1st period contribution' should not be included because it should have been already invoiced.
// That is also why we are checking the period dates.
export const NoteDocument = ({ member, debits }: PaymentDocumentProps) => {
    const { t } = useTranslation();

    // todo: should be provided from translations
    const locale = 'fr-FR';

    // Get the first valid date record (not a delayed payment)
    const firstValidDateRecord = findFirstValidDateRecord(debits);

    if (!firstValidDateRecord) {
        return;
    }

    const totalAmount = firstValidDateRecord.fees.reduce((a, b) => a + b.amount, 0);
    const totalAmountVat = firstValidDateRecord.fees.reduce(
        (a, b) => (b.taxPercentage ? a + b.tax : 0),
        0
    );

    const totalAmountNoVat = totalAmount - totalAmountVat;

    /**
     * Set document size to A4 at 150 PPI (1240 x 1754 px)
     */
    return (
        <div className="invoice flex flex-col w-[1240px] h-[1754px] p-[20mm]">
            <div className="flex flex-row">
                <div className="flex flex-col w-1/2">
                    <Logo />
                    <div className="flex flex-row mt-6">
                        <div className="w-1/2">{t('notes.document.due.date')}:</div>
                        <div>{debits.startDate}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-1/2">{t('notes.document.period')}:</div>
                        <div>
                            {debits.startDate}/{debits.endDate}
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-1/2">{t('notes.document.note.date')}:</div>
                        <div>{debits.currentDate}</div>
                    </div>
                    <div className="flex flex-row mt-6">
                        <div className="w-1/2">{t('invoice.to')}:</div>
                        <div>{getMemberName(member)}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-1/2">{t('invoice.address')}:</div>
                        <div>
                            {member?.mailing_house_number} {member?.mailing_street}
                            <br />
                            {member?.poste_code} {member?.mailing_city}
                        </div>
                    </div>
                </div>
                <div className="w-2/3 text-right">
                    <div>{t('invoice.company.name')}</div>
                    <div>{t('invoice.company.street')}</div>
                    <div>{t('invoice.company.city')}</div>
                    <div>{t('invoice.company.country')}</div>
                    <br />
                    <div>{t('invoice.head_office.po_box')}</div>
                    <div>{t('invoice.head_office.address')}</div>
                    <div>{t('invoice.head_office.website')}</div>
                    <div>{t('invoice.head_office.email')}</div>
                </div>
            </div>
            <div className="flex flex-col items-end my-6">
                <div className="flex flex-row invoice__club">
                    <div className="w-1/3">{t('invoice.club')}:</div>
                    <div className="w-2/3">{member?.home_club}</div>
                </div>
                <div className="flex flex-row invoice__club">
                    <div className="w-1/3">{t('invoice.company_id')}:</div>
                    <div>{t('invoice.company_tax_nr')}</div>
                </div>
            </div>
            <div className="flex my-6">
                <table className="" width="100%">
                    <tbody>
                        <tr className="">
                            <td className="w-5/12">{t('invoice.product.description')}</td>
                            <td className="w-2/12">{t('invoice.product.quantity')}</td>
                            <td className="w-2/12">{t('invoice.product.price_no_tax')}</td>
                            <td className="w-1/12">{t('invoice.tax')}</td>
                            <td className="w-2/12">{t('invoice.product.price_with_tax')}</td>
                        </tr>
                        {firstValidDateRecord.fees?.map((fee) => (
                            <tr key={fee.description}>
                                <td>{fee.description}</td>
                                <td>1</td>
                                <td>{getNumber(fee.amount, locale)}</td>
                                <td>{fee.taxPercentage}%</td>
                                <td>{getNumber(fee.amount - fee.tax, locale)}</td>
                            </tr>
                        ))}

                        <tr>
                            <td colSpan={4}>{t('invoice.product.total_with_tax')}</td>
                            <td>{getNumber(totalAmountNoVat, locale)}</td>
                        </tr>
                        <tr>
                            <td colSpan={4}>{t('invoice.product.total_tax')}</td>
                            <td>{getNumber(totalAmountVat, locale)}</td>
                        </tr>
                        <tr>
                            <td colSpan={4}>
                                <strong>{t('invoice.product.total_payment')}</strong>
                            </td>
                            <td>
                                <strong>{getPrice(totalAmount, locale)}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex grow">
                <div className="flex-col mt-auto w-full relative">
                    <div>{t('invoice.footer.tax_refund')}</div>
                    <div className="flex justify-between w-full border-t-[1px] border-b-[1px] border-anthracite mt-[2mm] py-[2mm]">
                        <div>
                            {t('invoice.club')}: {member?.home_club}
                            <br />
                            {t('invoice.footer.iban')}
                            <br />
                            {t('invoice.footer.bic')}
                            <br />
                            {t('invoice.footer.vat_nr')}
                            <br />
                            {t('invoice.company_id')}: {t('invoice.company_tax_nr')}
                        </div>
                    </div>
                    <div className="mt-[2mm]">{t('notes.document.explanation')}</div>
                </div>
            </div>
        </div>
    );
};
