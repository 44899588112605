import React from 'react';
import { Heading, Text } from '@basic-fit/design-system';

type HeaderProps = {
    className?: string;
    title: string | React.ReactNode;
    text?: string | React.ReactNode;
    image?: React.ReactNode;
    background?: 'orange' | 'transparent';
    testid?: string;
};

export const Header = ({
    className,
    title,
    text,
    image,
    background = 'orange',
    testid
}: HeaderProps) => {
    return (
        <header
            className={`max-h-[360px] relative flex justify-between ${
                background === 'orange' ? 'bg-gradient-orange text-white' : ''
            } ${className ?? ''}`}
        >
            <div className={`w-full p-l pr-l md:pr-0 sm:p-xxl ${image ? 'md:w-5/6' : ''}`}>
                <Heading
                    size={3}
                    className="mb-3"
                    color={background === 'orange' ? 'white' : 'anthracite'}
                    data-testid={`${testid}-title`}
                >
                    {title}
                </Heading>
                <Text
                    className={`${background === 'orange' ? 'text-white' : ''}`}
                    size={2}
                    data-testid={`${testid}-text`}
                >
                    {text}
                </Text>
            </div>
            {image && (
                <div className="relative hidden md:flex justify-center items-start flex-grow h-0">
                    <div className="w-[80%]">{image}</div>
                </div>
            )}
        </header>
    );
};
