import React, { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CancelMembershipProvider } from '@src/components/ContextProvider/CancellationContextProvider';
import CancelMembershipCheck from '@src/pages/CancelMembership/CancelMembershipCheck.page';
import CancelMembershipReasons from '@src/pages/CancelMembership/CancelMembershipReasons.page';

export const enum Path {
    Reasons = '/reasons',
    Check = '/check',
    Base = '/cancel-membership'
}

export const CancelMembershipRouter = (): ReactElement => {
    return (
        <CancelMembershipProvider>
            <Routes>
                <Route path={Path.Reasons} element={<CancelMembershipReasons />} />
                <Route path={Path.Check} element={<CancelMembershipCheck />} />
                <Route path="*" element={<Navigate to="reasons" />} />
            </Routes>
        </CancelMembershipProvider>
    );
};
