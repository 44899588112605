import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Text } from '@basic-fit/design-system';
import { Modal } from '@src/components/Modals/Modal';
import { useMember } from '@src/services/member/MemberProvider';
import { RejectReason } from '@src/utils/hooks/useChangeMembershipGuard';
import { DateTime } from 'luxon';

type UnableToChangeMembershipModalProps = {
    isOpen: boolean;
    reason?: RejectReason;
};

export const UnableToChangeMembershipModal = ({
    isOpen,
    reason
}: UnableToChangeMembershipModalProps) => {
    return (
        <Modal isOpen={isOpen} overlayClick>
            {(() => {
                switch (reason) {
                    case RejectReason.HAS_DEBT:
                        return <HasDebtContent />;
                    case RejectReason.HAS_RENEWAL_MEMBERSHIP:
                        return <HasRenewedMembership />;
                    case RejectReason.NO_MEMBERSHIP_OPTIONS:
                        return <NoMembershipOptionsContent />;
                    case RejectReason.ALL_IN_MEMBERSHIP:
                        return <AllInMembershipContent />;
                    case RejectReason.IS_FROZEN:
                        return <FrozenContent />;
                    default:
                        return null;
                }
            })()}
        </Modal>
    );
};

const HasDebtContent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <Heading size={5} className="mb-s">
                {t('401')}
            </Heading>
            <Text size={2}>{t('1628')}</Text>
            <Button
                data-cy="has-debt__button"
                variant="primary"
                className="mt-s w-full"
                onClick={() => navigate('/payments')}
            >
                {t('63')}
            </Button>
        </>
    );
};

const NoMembershipOptionsContent = () => {
    const { t } = useTranslation();
    return (
        <>
            <Heading size={5} className="mb-s">
                {t('1968')}
            </Heading>
            <Text size={2}>{t('membership.change.no_memberships')}</Text>
        </>
    );
};

const AllInMembershipContent = () => {
    const { t } = useTranslation();
    return (
        <>
            <Heading size={5} className="mb-s">
                {t('membership.change.unable_to_change')}
            </Heading>
            <Text size={2}>{t('membership.change.all_in_membership')}</Text>
        </>
    );
};

const FrozenContent = () => {
    const { t } = useTranslation();
    return (
        <>
            <Heading size={5} className="mb-s">
                {t('freeze.modal.membership.frozen.title')}
            </Heading>
            <Text size={2}>{t('freeze.modal.membership.frozen.text')}</Text>
        </>
    );
};

const HasRenewedMembership = () => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const { state: member } = useMember();
    return (
        <>
            <Heading size={5} className="mb-s">
                {t('renewal.extend.membership')}
            </Heading>
            <Text size={2}>
                {t('renewal.has.renewed').replace(
                    '%%date%%',
                    DateTime.fromISO(member.renewal_contract?.peopleMembershipStartDate)
                        .setLocale(language)
                        .toLocaleString()
                )}
            </Text>
        </>
    );
};
