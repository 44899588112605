import { useEffect } from 'react';
import { captureException } from '@src/utils/helpers/newRelic';

import { BalanceSheetProps } from './types/balanceSheet';
import { useFetch } from './useFetch';

type BalanceProps = {
    balance: number;
    reminderStatus: string;
    inDebt: boolean;
    isLoading: boolean;
    isError: boolean;
};

export const parseBalanceSheet = (
    balanceSheet: BalanceSheetProps | null
): Omit<BalanceProps, 'isLoading' | 'isError'> => {
    if (!balanceSheet) return { balance: 0, reminderStatus: '', inDebt: false };
    const { balanceAmount, reminderStatusOverride, totalBalanceAmount } = balanceSheet;

    const hasNegativeBalance = balanceAmount < 0 && !reminderStatusOverride;
    const balance = hasNegativeBalance ? totalBalanceAmount : balanceAmount;
    const inDebt = balance < 0;

    return {
        balance,
        reminderStatus: balanceSheet.reminderStatus,
        inDebt
    };
};

export const useBalanceSheet = (shouldFetch?: boolean): BalanceProps => {
    const {
        data: balanceSheet,
        isLoading,
        isError
    } = useFetch<BalanceSheetProps | null>(
        shouldFetch === false ? null : '/payments/get-balance-sheet'
    );

    useEffect(() => {
        if (!isError) return;

        captureException('failed to fetch balance sheet', { fn: 'useBalanceSheet' });
    }, [isError]);

    return {
        ...parseBalanceSheet(balanceSheet),
        isLoading,
        isError
    };
};
