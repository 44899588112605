import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Text } from '@basic-fit/design-system';
import { Form } from '@src/components/Form/Form';
import { TextInput } from '@src/components/Form/TextInput/TextInput';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { SuccessModal } from '@src/feature/ChangeMemberModals/Success.modal';
import { TooManyTriesModal } from '@src/feature/ChangeMemberModals/TooManyTries.modal';
import { useMember } from '@src/services/member/MemberProvider';

import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';
import { ChangeFiscalCodeContext } from './ChangeFiscalCode.machine';
import { useCanChangeFiscalCodeGuard } from './useFiscalCode.hook';

export const ChangeFiscalCodePage = (): JSX.Element => {
    const { t } = useTranslation();
    const { state: member } = useMember();

    useCanChangeFiscalCodeGuard();

    const fiscalCodeTable = [
        [
            <div key={useId()} className="flex justify-start items-center h-full w-full">
                {t('information.ficalcode.table.title')}
            </div>,
            <Bold key={useId()} className="group relative">
                {member.fiscal_code}
            </Bold>
        ]
    ];

    return (
        <ChangeFiscalCodeContext.Provider>
            <div className="h-full">
                <div className="hidden lg:block">
                    <Header
                        title={t('30')}
                        image={<LineSVG id="eclipse" className="absolute right-0 top-0" />}
                    />
                </div>
                <Section className="p-4 lg:p-0">
                    <div
                        className="mint-top"
                        data-cy="member-fiscalcode-block"
                        data-testid="member-fiscalcode-block"
                    >
                        <StrokeText
                            stroke="first"
                            color="anthracite"
                            className="mb-m"
                            text={t('details.change.fiscalcode.title')}
                        />
                        <Text size={2} className="mb-4 leading-[130%]">
                            {t('details.change.address.description')}
                        </Text>

                        {member.fiscal_code && (
                            <div
                                data-cy="member-current-fiscal-code"
                                data-testid="member-current-fiscal-code"
                                className="mb-6"
                            >
                                <Text size={2} bold className="!text-regular-m">
                                    {t('details.change.fiscalcode.table.header')}
                                </Text>
                                <TableFlex
                                    size="s"
                                    data={fiscalCodeTable}
                                    className="border-b border-anthracite-74"
                                />
                            </div>
                        )}

                        <ChangeFiscalCodeFlow />
                    </div>
                </Section>
            </div>
        </ChangeFiscalCodeContext.Provider>
    );
};

const ChangeFiscalCodeFlow = () => {
    const { t } = useTranslation();
    const [state, send] = ChangeFiscalCodeContext.useActor();
    const navigate = useNavigate();

    return (
        <>
            <Form
                onSubmit={({ fiscalcode }) => send({ type: 'SUBMIT_FISCAL_CODE', fiscalcode })}
                name="changeMyInformation"
                className="space-y-6"
            >
                <div className="space-y-3">
                    <Text size={2} bold className="!text-regular-m">
                        {t('information.ficalcode.table.title')}
                    </Text>
                    <TextInput
                        data-testid="member-input-fiscalcode"
                        data-cy="member-input-fiscalcode"
                        label={t('details.change.fiscalcode.form.header')}
                        name="fiscalcode"
                        required={true}
                        error={(() => {
                            if (!state.matches('error')) return;
                            if (
                                state.context.error ===
                                CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_RATE_LIMIT_EXCEEDED
                            )
                                return;

                            return t('details.change.fiscalcode.form.incorrect');
                        })()}
                        pattern={{
                            value: /^(?:\d{8}[A-Z]|[A-Z]\d{7,8}[A-Z])$/,
                            message: t('details.change.fiscalcode.form.incorrect')
                        }}
                    />
                </div>

                <div>
                    <Button
                        data-testid="member-input-submit-button"
                        data-cy="member-input-submit-button"
                        className="w-full"
                        type="submit"
                    >
                        {t('385')}
                    </Button>
                </div>
            </Form>
            <Button className="mt-[20px] w-full" onClick={() => navigate(-1)} theme="light">
                {t('162')}
            </Button>

            {state.matches('success') && (
                <SuccessModal
                    autoClose={true}
                    onCloseCb={() => {
                        navigate('/information');
                    }}
                />
            )}
            {state.matches('error') &&
                state.context.error ===
                    CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_RATE_LIMIT_EXCEEDED && (
                    <TooManyTriesModal
                        onCloseCb={() => {
                            navigate('/information');
                        }}
                    />
                )}
        </>
    );
};
