import { NODE_ENV } from 'refactor/services/utilities';

interface GTMEvent {
    eventCallback?: () => void;
}
interface PageViewEvent extends GTMEvent {
    event: 'custom_page_view';
    page_name: string;
}
interface ClickEvent extends GTMEvent {
    event: 'custom_click';
    click_type: string;
}

interface ErrorEvent extends GTMEvent {
    event: 'custom_error';
    page_name: string;
}

type DataLayerEvent = PageViewEvent | ClickEvent | ErrorEvent;

export function pushToDataLayer(data: DataLayerEvent): void {
    window.dataLayer ??= [];
    window.dataLayer.push(data);
    if (NODE_ENV === 'development') return data.eventCallback?.();
}
