import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { getPrice } from '@src/utils/helpers/priceHelpers';
import { NoteArticle } from '@src/utils/hooks/api/types/debits';

import titleCase from 'services/get-title-case';
import { TransactionRow } from './TransactionRow';

type DebitTableProps = {
    data: NoteArticle[];
    totalAmount: number;
};

export const Debit = ({ data, totalAmount }: DebitTableProps) => {
    const id = useId();
    const {
        i18n: { language }
    } = useTranslation();
    const { t } = useTranslation();

    const DebitTable = () => {
        return (
            <div key={id}>
                <TransactionRow
                    key={id}
                    className="font-heading-treble font-bold mt-xxs sm:mt-l py-xxs"
                    display="header"
                    title={titleCase(t('payments.membership.fee'))}
                    amount={
                        totalAmount
                            ? getPrice(-totalAmount, language, { format: 'withDigits' })
                            : '0'
                    }
                    isDebitRow={true}
                    data-cy="debit-table-header"
                    data-testid="debit-table-header"
                />

                {data.map((item, index) => (
                    <TransactionRow
                        key={index}
                        className="py-xxs"
                        title={<Text size={2}>{item.description}</Text>}
                        amount={
                            <Text>{getPrice(item.amount, language, { format: 'withDigits' })}</Text>
                        }
                        isDebitRow={true}
                        data-cy={`debit-${item.description}-${item.amount}`}
                        data-testid={`debit-${item.description}-${item.amount}`}
                    />
                ))}
            </div>
        );
    };

    return (
        <div className="border-solid border-b-[1px] border-grey py-xxs" data-testid="debit">
            <DebitTable />
        </div>
    );
};
