import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@src/components/Buttons/Button';
import { Form } from '@src/components/Form/Form';
import { TextInput } from '@src/components/Form/TextInput/TextInput';
import { Text } from '@src/components/Text/Text';
import { ResendCodeContentModal } from '@src/feature/ChangeMemberModals/Resend.modal';
import { SuccessModal } from '@src/feature/ChangeMemberModals/Success.modal';
import { TooManyTriesModal } from '@src/feature/ChangeMemberModals/TooManyTries.modal';
import { useMember } from '@src/services/member/MemberProvider';
import DOMPurify from 'isomorphic-dompurify';

import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';
import { ModalState } from '../machine-helpers';
import { ChangePhoneNumberContext } from './changePhoneNumber.machine';

export const VerifyCode = () => {
    const { t } = useTranslation();
    const [state, send] = ChangePhoneNumberContext.useActor();
    const { state: member } = useMember();

    const codeError = ChangePhoneNumberContext.useSelector((state) => {
        if (!state.context.codeError) return undefined;
        if (!state.matches('code.error')) return undefined;

        if (
            state.context.codeError ===
            CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_EMAIL_TOKEN_PASSED_INVALID
        ) {
            return t('details.change.email.code.error.noMatch');
        }
        if (
            state.context.codeError === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_RATE_LIMIT_EXCEEDED
        ) {
            // NOTE: rate limit error is handled in the modal
            return undefined;
        }
        return t('details.change.email.code.error.unexpectedError');
    });

    const shouldShowResend = ChangePhoneNumberContext.useSelector((state) => {
        return state.context.modal === ModalState.RESEND;
    });
    const shouldShowTooManyTries = ChangePhoneNumberContext.useSelector((state) => {
        return state.context.modal === ModalState.TOO_MANY_TRIES;
    });
    const shouldShowSuccess = ChangePhoneNumberContext.useSelector((state) => {
        return state.context.modal === ModalState.SUCCESS;
    });

    function closeModal() {
        send('CLOSE_MODAL');
    }

    return (
        <div data-cy="verify-code" data-testid="verify-code">
            <Text
                size="m"
                className="mb-8"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        t('details.change.email.code.description').replace(
                            '%%email%%',
                            member.email
                        )
                    )
                }}
            />
            <Form
                onSubmit={({ code }) => send({ type: 'SUBMIT_CODE', code })}
                name="verifyCode"
                className="space-y-5"
            >
                <TextInput
                    required
                    name="code"
                    type="number"
                    maxLength={6}
                    minLength={6}
                    label={t('details.change.email.code.label')}
                    error={codeError}
                    onInput={() => send('EDITING')}
                    data-cy="code"
                    data-testid="code"
                    autoComplete="one-time-code"
                />
                <div className="flex flex-row">
                    <Text size="s" className="text-anthracite">
                        {t('details.change.email.code.help')}
                        &nbsp;
                        <button
                            type="button"
                            className="underline"
                            onClick={(e) => {
                                e.preventDefault();
                                send({ type: 'OPEN_MODAL', modal: ModalState.RESEND });
                            }}
                            data-cy="resend-code"
                            data-testid="resend-code"
                        >
                            {t('details.change.email.code.resend')}
                        </button>
                    </Text>
                </div>
                <Button
                    type="submit"
                    text={t('128')}
                    loading={state.matches('code.verifying')}
                    disabled={state.matches('code.verifying')}
                    data-cy="submit-code"
                    data-testid="submit-code"
                />
                <Button
                    type="button"
                    onClick={() => send('BACK')}
                    className="!bg-white hover:bg-white !text-anthracite"
                    text={t('162')}
                    data-cy="back"
                    data-testid="back"
                />
            </Form>
            {shouldShowResend && (
                <ResendCodeContentModal
                    email={state.context.email}
                    emailTokenType={state.context.flow}
                    onCloseCb={closeModal}
                />
            )}
            {shouldShowTooManyTries && <TooManyTriesModal onCloseCb={closeModal} />}
            {shouldShowSuccess && <SuccessModal onCloseCb={closeModal} autoClose />}
        </div>
    );
};
