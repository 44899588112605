import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { InformationBlock } from '@src/components/InformationBlock/InformationBlock';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { useMember } from '@src/services/member/MemberProvider';

import { toLocal } from 'services/date-converter';

export const ContractRenewalInfo = () => {
    const id1 = useId();
    const id2 = useId();
    const { t } = useTranslation();
    const { state: member } = useMember();

    if (!member?.renewal_contract?.peopleMembershipStartDate) return null;
    if (!member?.renewal_contract?.peopleMembershipContractEndDate) return null;

    const tableData = [
        [
            t('43'),
            <Bold key={id1}> {toLocal(member.renewal_contract.peopleMembershipStartDate)} </Bold>
        ],
        [
            t('435'),
            <Bold key={id2}>
                {toLocal(member.renewal_contract.peopleMembershipContractEndDate)}
            </Bold>
        ]
    ];

    return (
        <InformationBlock className="mb-l">
            {t('renewal.renewed.body')}
            <TableFlex data={tableData} size="s" />
        </InformationBlock>
    );
};
