import React, { ButtonHTMLAttributes } from 'react';
import { Icon } from '@src/components/Icons/Icons';

import { Spinner } from '../Spinner/Spinner';

interface IPrimaryButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    text?: string | JSX.Element;
    icon?: string | JSX.Element;
    theme?: 'solid' | 'outline';
    color?: 'purple' | 'orange' | 'grey' | 'anthracite' | 'naked';
    type?: 'button' | 'submit' | 'reset';
    loading?: boolean;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button = ({
    text,
    theme = 'solid',
    color = 'purple',
    className = '',
    icon,
    loading = false,
    disabled,
    type,
    onClick,
    ...rest
}: IPrimaryButton): JSX.Element => {
    return (
        <button
            type={type}
            disabled={disabled}
            onClick={onClick}
            className={`w-[100%] py-[16px] font-bold font-heading-treble transition-all duration-300 relative flex items-center  text-[1rem] z-[0] text-white cursor-pointer shadow-transparent shadow-[inset_0_0_0_0] text-center justify-center focus:shadow-[inset_0_0_0_3px] focus:shadow-mint group ${
                theme === 'solid' && color === 'purple'
                    ? `bg-primary focus:bg-primary-focus active:bg-primary-active disabled:bg-disabled ${
                          disabled ? '' : 'hover:bg-primary-hover'
                      }`
                    : ''
            }
                ${
                    color === 'orange'
                        ? ` bg-orange active:bg-orange-active disabled:bg-disabled ${
                              disabled ? '' : 'hover:bg-orange-hover '
                          }`
                        : ''
                }
                ${
                    color === 'grey'
                        ? ` bg-jersey-grey hover:bg-jersey-grey/50 active:bg-orange-active disabled:bg-disabled ${
                              disabled ? '' : 'hover:bg-jersey-grey/50 '
                          } bg-alpha-[.6]`
                        : ''
                }
                ${
                    theme === 'outline' || color === 'anthracite'
                        ? ` bg-none !shadow-[inset_0_0_0_3px] shadow-[anthracite] focus:!shadow-mint disabled:text-disabled !text-anthracite `
                        : ''
                }
                ${
                    theme === 'outline' && color === 'purple'
                        ? ` bg-none !shadow-[inset_0_0_0_3px] shadow-[primary] focus:!shadow-mint disabled:text-disabled !text-primary `
                        : ''
                }                
                ${
                    color === 'naked'
                        ? ` bg-none !shadow-none disabled:text-disabled !text-anthracite `
                        : ''
                }
                ${icon ? 'focus:!shadow-[inset_0_0_0_0]' : ''}
                ${className}
            `}
            {...rest}
        >
            <span
                className={`
                    relative z-[1] uppercase inline-flex items-center group-disabled:opacity-[.6]
                    ${icon ? 'w-[100%] px-[30px] justify-between' : ''}
                    ${
                        color === 'naked'
                            ? `
                                before:content-[''] before:w-[100%] before:h-[3px] before:top-[100%] before:left-[0] before:absolute
                                before:scale-x-[0] before:bg-anthracite before:border-[0] before:transition-[all] before:duration-300
                                before:origin-[left_center]
                                group-hover:before:scale-x-[1]
                                group-focus:before:bg-mint group-focus:before:scale-x-[1]
                                group-active:before:bg-orange-active group-active:before:scale-x-[1]
                            `
                            : ''
                    }
                `}
            >
                <span className="relative z-[1]">{text}</span>
                {icon && !loading && (
                    <span className="ml-s text-[16px]">
                        {typeof icon === 'string' ? <Icon id={icon} /> : icon}
                    </span>
                )}
                {loading && (
                    <div className="mx-xxs">
                        <Spinner size="s" />
                    </div>
                )}
            </span>
        </button>
    );
};
