import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import ChangeEmailPage from '@src/pages/MyInformation/ChangeEmail/ChangeEmail.page';
import { ChangeFiscalCodePage } from '@src/pages/MyInformation/ChangeFiscalCode/ChangeFiscalCode.page';
import ChangeMyInformation from '@src/pages/MyInformation/ChangeMyInformation.page';
import ChangePaymentInfo from '@src/pages/MyInformation/ChangePaymentInfo/ChangePaymentInfo.page';
import ChangePhoneNumberPage from '@src/pages/MyInformation/ChangePhoneNumber/ChangePhoneNumber.page';
import MarketingPreferences from '@src/pages/MyInformation/MarketingPreferences';
import MyInformation from '@src/pages/MyInformation/MyInformation/MyInformation.page';

import { RouteProps } from '.';

const routes: RouteProps[] = [
    { path: 'change-email', Component: ChangeEmailPage },
    { path: 'change-phone', Component: ChangePhoneNumberPage },
    { path: 'change-payment-info', Component: ChangePaymentInfo },
    { path: 'change-address', Component: ChangeMyInformation },
    { path: 'change-fiscal-code', Component: ChangeFiscalCodePage },
    { path: 'marketing-preferences', Component: MarketingPreferences },
    { path: '', Component: MyInformation }
];

export const MyInformationRouter = (): ReactElement => {
    return (
        <Routes>
            {routes.map((route, index) => (
                <Route
                    key={`my-information-route-${index}`}
                    path={route.path}
                    element={<route.Component />}
                />
            ))}
        </Routes>
    );
};
