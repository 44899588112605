import * as React from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { pushToDataLayer } from '@src/services/utilities/analytics.service';
import { isMobile } from '@src/utils/helpers/device';
import DOMPurify from 'isomorphic-dompurify';

type ConfirmationModalProps = {
    isOpen: boolean;
};

export const ConfirmationModal = ({ isOpen }: ConfirmationModalProps) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const isEventPushed = useRef(false);

    if (isOpen === false) return null;

    if (isEventPushed.current === false) {
        // Ensure that the event is only pushed once
        isEventPushed.current = true;
        pushToDataLayer({ event: 'custom_page_view', page_name: 'cancellation success' });
    }

    return (
        <Modal
            isOpen={isModalOpen}
            onModalClose={() => {
                pushToDataLayer({
                    event: 'custom_click',
                    click_type: 'close',
                    eventCallback: () => setIsModalOpen(false)
                });
            }}
            data-testid="cancel-membership-confirmation-modal"
        >
            <Heading size={isMobile() ? 2 : 5} className="mb-2">
                {t('cancellation.success.modal.title')}
            </Heading>
            <div className="flex flex-col gap-2">
                <Text size={2}>{t('cancellation.success.modal.text.sorry')}</Text>
                <div className="flex items-start space-x-2 mb-2">
                    <span className="before:content-['•'] before:text-xl" />
                    <Text size={2} className="break-words">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    t('cancellation.success.modal.text.email')
                                )
                            }}
                        />
                    </Text>
                </div>
                <Text size={2}>{t('cancellation.success.modal.text.thanks')}</Text>
                <Button
                    text={t('cancellation.success.modal.button.continue')}
                    onClick={() => {
                        pushToDataLayer({
                            event: 'custom_click',
                            click_type: 'continue',
                            eventCallback: () => setIsModalOpen(false)
                        });
                    }}
                    data-testid="cancel-membership-confirmation-modal-button"
                    className="mt-4"
                />
            </div>
        </Modal>
    );
};
