import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import { Button, Heading } from '@basic-fit/design-system';
import {
    LanguageLabelProps,
    LanguageProps,
    OptionWithLanguage,
    SelectedWithLanguage
} from '@src/components/Form/SelectElement/SelectComponents';
import { SelectElement } from '@src/components/Form/SelectElement/SelectElement';
import { Modal } from '@src/components/Modals/Modal';
import { getCurrentLanguage, getLanguages } from 'parameters/languages';

import setLanguage from '../../../../services/API/set-language';

const languageOptions = getLanguages().map((lang) => ({
    value: {
        country: lang.countryForApiRequest,
        language: lang.languageShort
    },
    label: lang.language,
    language: lang.languageShort
}));

type ModalProps = {
    isOpen: boolean;
    onClose?: () => void;
};

export const LanguageSwitch = ({ isOpen, onClose }: ModalProps) => {
    const [lang, setLang] = useState<LanguageProps>();

    const {
        t,
        i18n: { language }
    } = useTranslation();

    const changedLanguage = (
        option: SingleValue<LanguageLabelProps> | MultiValue<LanguageLabelProps>
    ) => {
        setLang((option as LanguageLabelProps).value);
    };

    const submit = async () => {
        try {
            if (!lang) return;

            await setLanguage(lang);
            window.location.replace(location.pathname);
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Set default language based on __MEMBER_CONTEXT__
     */
    const currentLanguage = getCurrentLanguage();
    const defaultValue = {
        value: {
            country: currentLanguage.countryForApiRequest,
            language: currentLanguage.languageShort
        },
        label: currentLanguage.language,
        language: currentLanguage.languageShort
    };

    return (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <Heading data-testid="change-language__heading" size={5}>
                {language ? t('442') : 'Change your language'}
            </Heading>

            <SelectElement
                className="mt-m mb-xxs"
                defaultValue={defaultValue}
                options={languageOptions}
                components={{ SingleValue: SelectedWithLanguage, Option: OptionWithLanguage }}
                isSearchable={false}
                menuPosition={'fixed'}
                onChange={changedLanguage}
            />

            <Button
                data-testid="change-language__submit"
                className="mt-m w-full"
                variant="primary"
                disabled={!lang}
                onClick={submit}
            >
                {language ? t('356') : 'confirm'}
            </Button>
        </Modal>
    );
};
