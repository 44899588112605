import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@basic-fit/design-system';
import { AddOn } from '@src/components/Cards/AddOn';
import { Section } from '@src/components/Layout/Section';
import { AddOnsModal } from '@src/pages/Membership/Membership/components/Modals/AddOns.modal';
import { useMember } from '@src/services/member/MemberProvider';
import {
    getArticleImage,
    getArticleIntervalText,
    getBuyableArticles,
    handlePocDiscount,
    hasAlreadyBought,
    isActive,
    isOneTimeArticle
} from '@src/utils/helpers/articleHelpers';
import { captureException } from '@src/utils/helpers/newRelic';
import { ArticleByPaymentSchedule } from '@src/utils/hooks/api/types/articleByPaymentSchedule';
import { Article, useArticles } from '@src/utils/hooks/api/useArticles';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';
import { useSWRConfig } from 'swr';

export const AddOns = () => {
    const { mutate } = useSWRConfig();
    const { articles } = useArticles();

    const {
        t,
        i18n: { language }
    } = useTranslation();

    const [data, setData] = useState<Article[]>();
    const [selectedArticle, setSelectedArticle] = useState<Article>();
    const [modalOpen, setModalOpen] = useState(false);

    const { state: member } = useMember();
    const { pocDiscount } = useMBFFeatures();

    /**
     * Get the articles that are available for the current payment schedule and club
     * Todo: We should rewrite this to use the new API `/api/v1/Articles/GetByPaymentschedulePromotionClub/{paymentScheduleId}`
     */
    const { data: availableArticles, isError: availableArticlesError } = useFetch<
        ArticleByPaymentSchedule[]
    >(
        `/memberships/get-articles-by-paymentschedule?paymentscheduleId=${member?.payment_plan_id_g}&clubId=${member.home_club_id}`
    );

    useEffect(() => {
        if (availableArticlesError) {
            captureException('failed to fetch articles by payment schedule', {
                component: 'AddOns',
                peopleId: member.people_id_g
            });
        }
    }, [availableArticlesError]);

    /**
     * Merge availableArticles and boughtArticles for the render loop
     */
    useEffect(() => {
        if (!member || !articles || !availableArticles) return;

        const buyableArticles = getBuyableArticles([
            ...articles.availableArticles,
            ...articles.boughtArticles
        ]);

        // get article id's from payment schedule
        const availableIds = new Set(availableArticles?.map(({ artc_id }) => artc_id) || []);
        const boughtIds = new Set(articles.boughtArticles.map(({ articleId }) => articleId));
        const addons = buyableArticles
            .map((item) => {
                if (item.articleDescription.includes('Personal Online Coach') && pocDiscount) {
                    return handlePocDiscount<Article>(pocDiscount, item, item.articlePrice);
                }
                return item;
            })
            .filter((item) => {
                const articleDescription = String(item.articleDescription).toLowerCase();
                // show freeze article only if it's already bought
                if (articleDescription.includes('freeze') && !boughtIds.has(item.articleId)) {
                    return false;
                }
                if (articleDescription.includes('yanga') && isActive(item)) return true;
                return availableIds.has(item.articleId);
            });

        setData(addons);
    }, [member, articles, availableArticles]);

    const handleArticle = (item: Article) => {
        setSelectedArticle(item);
        setModalOpen(true);
    };

    const handleModalClose = async () => {
        setModalOpen(false);
        await mutate('/memberships/get-articles');
    };

    return (
        <Section id="add-ons">
            <Heading size={4} className="sm:px-0 px-l">
                {t('104')}
            </Heading>

            {data && (
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-l mt-l">
                    {data?.map((item: Article) => (
                        <div key={item.articleId} className="sm:p-0 px-l">
                            <AddOn
                                title={item.articleShortDescription}
                                discountText={item.discountText}
                                originalPrice={item.originalPrice}
                                content={t(item.articleLongDescription)}
                                image={getArticleImage(item.articleShortDescription)}
                                price={item.articlePrice}
                                period={t(getArticleIntervalText(item, member))}
                                onetime={isOneTimeArticle(item)}
                                toggle={{
                                    on: t('351'),
                                    off: t('352'),
                                    active: isActive(item),
                                    changeOnClick: false
                                }}
                                button={{ text: t('77') }}
                                onClick={() => handleArticle(item)}
                                data-testid={item.articleShortDescription
                                    .toLowerCase()
                                    .split(' ')
                                    .join('-')}
                            />
                        </div>
                    ))}
                </div>
            )}

            {selectedArticle && (
                <AddOnsModal
                    isOpen={modalOpen}
                    onClose={handleModalClose}
                    article={selectedArticle}
                    articleBought={hasAlreadyBought(articles.boughtArticles, selectedArticle)}
                    membershipId={member.id_g}
                    lang={language}
                />
            )}
        </Section>
    );
};
