import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Modal } from '@src/components/Modals/Modal';
import { Bold } from '@src/components/Text/Bold';
import { getDayAndMonth, getWeekdayFromString } from '@src/utils/helpers/date';

import titleCase from 'services/get-title-case';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    dates: string[];
};

export const UpcomingPaymentsModal = ({ isOpen, onClose, dates }: ModalProps) => {
    const { t } = useTranslation();

    const getWeekday = (date: string) => {
        return getWeekdayFromString(date) != '' ? getWeekdayFromString(date) : date;
    };

    return (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <Heading size={7} className="mb-xs" data-cy="debits-modal-title">
                {titleCase(t('payments.future.periods'))}
            </Heading>

            <Text className="mb-xs first-letter:uppercase">
                {t('payments.future.periods.description') + ':'}
            </Text>

            <div
                className="w-full divide-y divide-anthracite-74 text-regular-s border-solid border-b-[1px] border-grey"
                data-testid="debits-future-dates-container"
            >
                {dates.map((date) => (
                    <div
                        key={date}
                        className="flex xs:flex-row flex-wrap py-xs justify-between"
                        data-cy={`debits-dates-${date}`}
                        data-testid={`debits-dates-${date}`}
                    >
                        <Text>
                            {titleCase(getWeekday(date))} <Bold>{getDayAndMonth(date, true)}</Bold>
                        </Text>
                    </div>
                ))}
            </div>
        </Modal>
    );
};
