import { isApp } from '@src/utils/helpers/window';

/**
 * When the `share()` method is ran in the native app we use the `postMessage` api to communicate back and forth between the
 * WebView and the OS. `ShareMessage` is an interface that defines the message we share with the app.
 *
 * ⚠️ Changes in this interface should be reflected in the app as well. Make sure to confirm this with your app neighbours!
 */
export type ShareURLMessage = string;
/**
 * The options for this message are directly interpreted by the native app using a package called `react-native-share`.
 * This allows us to directly set all the options that we require for the share window.
 *
 * Reference this url for the full list of options that you can use:
 * https://react-native-share.github.io/react-native-share/docs/share-open/#supported-options
 *
 * This interface currently only implements the options we actually use but anything in the linked docs
 * can be added to the `data` property in this interface without breaking anything in the app.
 */
// TODO: Add correct event data to native app

/**
 * These options are a narrowed down interface of the overlapping options
 * for navigator.share and postMessage and provide a singular interface that
 * can be used to call this. It uses `navigator.share` as its base because this
 * api is closest to the developers working in this project.
 */
interface ShareOptions {
    url: string;
    title: string;
    text?: string;
}

/**
 * Isomorphic function that lets you open a share window regardless of environment.
 * ⚠️ Note of caution: This function will throw if ran outside of an environment that
 * supports sharing. Make sure you provide alternative behaviour for sharing using
 * the `canShare` function from this same module.
 */
export const share = async (options: ShareOptions) => {
    /** If this loaded in the App's WebView, use postMessage */
    if (isApp() === true) {
        if (window.ReactNativeWebView?.postMessage) {
            const { url, title, text } = options;

            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    url,
                    title,
                    message: text
                })
            );

            return;
        }
    }

    if (navigator?.share && navigator.canShare(options)) {
        /** Otherwise try the browser's navigator api */
        return await navigator.share(options);
    }
};

/**
 * Determines if your current environment supports sharing.
 * Either through the OS or the browser. When sharing from the browser, only https is supported
 */
export const canShare = (): boolean => {
    return (
        window.ReactNativeWebView?.postMessage ||
        (window.location.protocol.startsWith('https') && !!navigator.canShare())
    );
};
