import React, { useEffect, useRef, useState } from 'react';
import { ChangeMembership } from '@src/components/ChangeMembership/ChangeMembership';
import { FAQ } from '@src/components/FAQ/FAQ.component';
import { FrozenOptions } from '@src/components/FreezeMembership/FrozenOptions.component';
import { Section } from '@src/components/Layout/Section';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { VisitsMetrics } from '@src/components/Visits/Visits';
import { PrepaidRenewalSection } from '@src/pages/Membership/Membership/components/PrepaidRenewalSection/PrepaidRenewalSection';
import { MemberDetails } from '@src/pages/Overview/components/Content/Components/MemberDetails';
import { useMember } from '@src/services/member/MemberProvider';
import { getActiveMembership } from '@src/services/membership.service';
import { canRenewMembership, isEmployee, isGerman } from '@src/utils/helpers/memberHelpers';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';

import googleTagManagerPush from 'services/google-tag-manager-push';
import { CTA } from './Components/CTA.overview';
import { Friends } from './Components/Friends.overview';

export const OverviewContent = (): JSX.Element => {
    const { state: member } = useMember();
    const { prepaidRenewal } = useMBFFeatures();

    /** get active membership */
    const [activeMembership, setActiveMembership] = useState<any>(false);
    const effectRan = useRef(false);

    useEffect(() => {
        const activeMem = getActiveMembership(member);
        setActiveMembership(activeMem);

        if (!effectRan.current) {
            googleTagManagerPush.viewPageEvent('view main page');
        }
        effectRan.current = true;
    }, []);

    const { data: homeClubHasActiveFrozenCampaign } = useFetch<any>(
        '/extra/is-club-in-active-frozen-campaign'
    );

    const canFreezeMembership = () =>
        homeClubHasActiveFrozenCampaign &&
        !member.membershipIsFrozen &&
        homeClubHasActiveFrozenCampaign.active;

    const memberHasFriends = () =>
        activeMembership &&
        (activeMembership.display_name === 'PREMIUM' || activeMembership.display_name === 'DUO');
    const memberHasDebt = () => !!member.inDebt;

    return (
        <>
            {isGerman(member) && <ChangeMembership />}

            {prepaidRenewal && canRenewMembership(member) && <PrepaidRenewalSection />}

            <Section>
                <VisitsMetrics />
            </Section>

            <Wrapper>
                {!isEmployee(member) && (
                    <>
                        {canFreezeMembership() && (
                            <Section>
                                <FrozenOptions />
                            </Section>
                        )}

                        {/* {!memberHasFriends() && (
                            <Section>
                                <NoFriends />
                            </Section>
                        )} */}
                        {memberHasFriends() &&
                            !memberHasDebt() && ( // friends
                                <Section>
                                    <Friends />
                                </Section>
                            )}

                        <Section>
                            <MemberDetails member={member} />
                        </Section>
                    </>
                )}

                {/* FAQ */}
                <Section>
                    <FAQ category="all" />
                </Section>

                <Section>
                    <CTA />
                </Section>
            </Wrapper>
        </>
    );
};
