import axios from 'axios';

import { domain, headers } from '.';

interface cancelMembershipProps {
    cancelReason: string;
    reasonDescription: string;
    peopleMembershipId: string;
}
export const cancelMembership = async ({
    cancelReason,
    reasonDescription,
    peopleMembershipId
}: cancelMembershipProps) => {
    const response = await axios({
        method: 'POST',
        url: `${domain}/memberships/insert-cancellation`,
        headers,
        data: { cancelReason, peopleMembershipId, reasonDescription }
    });
    return response;
};
