import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { ModalErrorState } from '@src/components/Modals/ModalErrorState';
import { StockImage } from '@src/components/StockImage/StockImage';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { captureException } from '@src/utils/helpers/newRelic';
import { SecondaryMemberProps } from '@src/utils/hooks/api/types/secondaryMember';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { usePost } from '@src/utils/hooks/api/usePost';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import DOMPurify from 'isomorphic-dompurify';
import { useSWRConfig } from 'swr';

import updateMemberInfo from '../../../../../../services/API/update-member-info';

type ModalProps = {
    isOpen: boolean;
    onClose?: () => void;
};

export const QRCodeAccessModal = ({ isOpen, onClose }: ModalProps) => {
    const { t } = useTranslation();

    const { awaiting: isLoading, awaitSpin } = useAwaitSpinner();
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const { mutate } = useSWRConfig();
    const { submit } = usePost();

    const { data: secondaryMember } = useFetch<SecondaryMemberProps>(
        '/secondary-member/get-secondary-member'
    );

    const [secondaryPeopleMembershipId, setSecondaryPeopleMembershipId] = useState<string | null>(
        null
    );
    useEffect(() => {
        const { peopleMembershipId } = secondaryMember?.membership || {};
        setSecondaryPeopleMembershipId(peopleMembershipId);
    }, [secondaryMember]);

    const handleModalClose = async () => {
        if (success) {
            await awaitSpin(async () => await mutate('/member/get-member'));
        }
        onClose?.();
    };

    const changeAccessTypeHandler = async () => {
        try {
            setDisabled(true);

            await awaitSpin(async () => {
                await updateMemberInfo({ accessType: 'qr' });
            });
            if (secondaryMember && secondaryPeopleMembershipId) {
                const response = await awaitSpin(() =>
                    submit('/secondary-member/disable-secondary-member', {
                        peopleMembershipId: secondaryPeopleMembershipId
                    })
                );

                if (!response.error) {
                    await mutate('/secondary-member/get-secondary-member', null);
                }
            }

            captureException('successfully activated qr-code', {
                component: 'QRCodeAccessModal',
                fn: 'changeAccessTypeHandler'
            });

            setSuccess(true);
        } catch (error) {
            setError(true);
            const captureError = error instanceof Error ? error : new Error(String(error));

            captureException(captureError, {
                reason: 'failed to activate qr-code',
                component: 'QRCodeAccessModal'
            });
        }
        setDisabled(false);
    };

    const modalContent = (
        <>
            <StrokeText
                className="text-anthracite"
                text={t('access-change.modal.qrcode.title', 'Change to QR-Code')}
                color="anthracite"
                stroke="last"
            />
            {success ? (
                <>
                    <StockImage className="mx-auto min-h-[15rem]" id="qr_code" />

                    <Text size={2} className="my-m">
                        {t('access-change.modal.qrcode.success-body')}
                    </Text>
                    <Button
                        data-cy="change-access-type__modal-success"
                        data-testid="change-access-type__modal-success"
                        color="naked"
                        text={t('162')}
                        onClick={handleModalClose}
                    />
                </>
            ) : (
                <>
                    <Text className="mt-s" size={2}>
                        {t('access-change.modal.qrcode.body')}
                    </Text>
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(t(`access-change.modal.qrcode.warning`))
                        }}
                        className="mt-s"
                        size={2}
                    />

                    <Button
                        className="mt-m"
                        text={t(
                            'access-change.modal.qrcode.change-access-button',
                            'Change to qr-code'
                        )}
                        onClick={changeAccessTypeHandler}
                        loading={isLoading}
                        disabled={disabled}
                        data-cy="change-access-type__modal-confirm"
                        data-testid="change-access-type__modal-confirm"
                    />
                    <Button
                        text={t('162')}
                        color="naked"
                        data-cy="change-access-type__modal-close"
                        onClick={handleModalClose}
                    />
                </>
            )}
        </>
    );

    return (
        <Modal isOpen={isOpen} onModalClose={handleModalClose}>
            {!error && modalContent}
            {error && (
                <ModalErrorState
                    body={t(
                        'access-change.modal.error-body',
                        'We couldn’t change your access method to QR-code. Please contact customer service to help you with this.'
                    )}
                />
            )}
        </Modal>
    );
};
