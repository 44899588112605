import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@src/components/Buttons/Button';
import { useMember } from '@src/services/member/MemberProvider';
import { isMobile } from '@src/utils/helpers/device';
import { GetDeviceInfoProps } from '@src/utils/hooks/api/types/getDeviceInfo';

interface IChangeAccessButton {
    deviceInfo: GetDeviceInfoProps;
    canSwapDevice: boolean;
    onClick: () => void;
}

export const ChangeAccessButton = ({ deviceInfo, canSwapDevice, onClick }: IChangeAccessButton) => {
    const { t } = useTranslation();

    const {
        state: { accessType }
    } = useMember();

    const { deviceId, deviceDescription } = deviceInfo || {};

    const isCardType = accessType === 'card';

    if (!isCardType && deviceId) {
        deviceInfo.deviceDescription ||= t('phone');
    }

    if (!isCardType && !deviceDescription && isMobile()) {
        return null;
    }

    if (!isCardType && deviceDescription && canSwapDevice) {
        return (
            <Button
                theme="outline"
                text={t('access-change.device.button')}
                onClick={onClick}
                className="mb-m"
                data-cy="change-qr-device"
                data-testid="change-qr-device"
            />
        );
    }

    return isCardType ? (
        <Button
            data-cy="change-access-type"
            data-testid="change-access-type"
            text={t('change-access.card.button')}
            onClick={onClick}
        />
    ) : null;
};
