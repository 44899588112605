import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@src/components/Buttons/Button';
import { Error } from '@src/components/Form/Error/Error.component';
import { Form } from '@src/components/Form/Form';
import { TextInput } from '@src/components/Form/TextInput/TextInput';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { Text } from '@src/components/Text/Text';
import { TooManyTriesModal } from '@src/feature/ChangeMemberModals/TooManyTries.modal';
import { useMember } from '@src/services/member/MemberProvider';

import googleTagManagerPush from 'services/google-tag-manager-push';
import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';
import { ModalState } from '../machine-helpers';
import { ChangePaymentInformationContext } from './changePaymentInfo.machine';

export const UpdatePaymentInformationComponent = () => {
    const [state, send] = ChangePaymentInformationContext.useActor();
    const { t } = useTranslation();
    const { state: member } = useMember();
    const navigate = useNavigate();

    const ibanError = ChangePaymentInformationContext.useSelector((state) => {
        if (!state.matches('paymentInfo.error')) return undefined;
        if (!state.context.ibanError) return undefined;

        if (state.context.ibanError === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_IBAN_INVALID) {
            return t('details.change.email.iban.invalid');
        }
    });

    const tokenError = ChangePaymentInformationContext.useSelector((state) => {
        if (!state.matches('paymentInfo.error')) return undefined;
        if (!state.context.tokenError) return undefined;

        if (
            state.context.tokenError === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_RATE_LIMIT_EXCEEDED
        ) {
            return undefined;
        }

        return t('details.change.unexpectedError');
    });

    const shouldShowTooManyTries = ChangePaymentInformationContext.useSelector((state) => {
        return state.context.modal === ModalState.TOO_MANY_TRIES;
    });

    const hasCurrentPaymentInfo = Boolean(member.iban && member.iban_account_holder);

    return (
        <div data-cy="change-payment-info" data-testid="change-payment-info">
            <Text size="m" className="mb-4 leading-[130%]">
                {t('details.change.address.description')}
            </Text>
            {hasCurrentPaymentInfo && (
                <div
                    className="mb-6"
                    data-cy="current-payment-info"
                    data-testid="current-payment-info"
                >
                    <Text
                        size="m"
                        className="!text-regular-m lowercase first-letter:uppercase font-bold"
                    >
                        {t('details.change.payment.new.tableHeader')}
                    </Text>
                    <TableFlex
                        size="s"
                        data={[
                            [t('160'), <Bold key="">{member.iban_account_holder}</Bold>],
                            [t('397'), <Bold key="">{member.iban}</Bold>]
                        ]}
                        className="border-b border-anthracite-74"
                    />
                </div>
            )}
            <Form
                name="updatePaymentInfo"
                onSubmit={({ accountHolder, iban }) => {
                    googleTagManagerPush.changeMemberInfo();
                    return send({ type: 'SUBMIT', accountHolder, iban });
                }}
                className="space-y-3"
                useCache={false}
            >
                <Text
                    size="m"
                    className="!text-regular-m lowercase first-letter:uppercase font-bold"
                >
                    {t('details.change.payment.new.formHeader')}
                </Text>
                <TextInput
                    required
                    type="text"
                    name="accountHolder"
                    label={t('160')}
                    defaultValue={state.context.accountHolder}
                    maxLength={{ value: 50, message: t('400') }}
                    data-cy="new-account-holder"
                    data-testid="new-account-holder"
                />
                <TextInput
                    required
                    type="text"
                    name="iban"
                    label={t('397')}
                    error={ibanError}
                    defaultValue={state.context.iban}
                    data-cy="new-iban"
                    data-testid="new-iban"
                />

                {tokenError && <Error error={tokenError} />}
                <div className="mt-m">
                    <Button
                        type="submit"
                        text={t('128')}
                        loading={state.matches('paymentInfo.verifying')}
                        disabled={state.matches('paymentInfo.verifying')}
                        data-cy="submit-payment-details"
                        data-testid="submit-payment-details"
                    />
                    <Button
                        type="button"
                        onClick={() => navigate('/information')}
                        text={t('162')}
                        color="naked"
                        data-cy="back"
                        data-testid="back"
                    />
                </div>
            </Form>
            {shouldShowTooManyTries && (
                <TooManyTriesModal
                    onCloseCb={() => {
                        send('CLOSE_MODAL');
                    }}
                />
            )}
        </div>
    );
};
