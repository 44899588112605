import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Text } from '@basic-fit/design-system';

import { Modal } from './Modal';

interface DownloadErrorModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const DownloadErrorModal: React.FC<DownloadErrorModalProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <div className="flex flex-col gap-4 p-4">
                <Heading size={5} className="mb-2">
                    {t('download.error.modal.title')}
                </Heading>
                <Text size={2}>{t('download.error.modal.body')}</Text>
                <Button variant="primary" onClick={onClose} fullwidth>
                    {t('download.error.modal.button')}
                </Button>
            </div>
        </Modal>
    );
};
