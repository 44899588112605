import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Modal, Text } from '@basic-fit/design-system';
import { setToSessionCache } from '@src/services/utilities/sessionCache.service';

interface FiscalCodeModalProps {
    isOpen: boolean;
    onClose: () => void;
    cancelUrl: string;
    onCancel?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const FiscalCodeModal = ({ isOpen, onClose, cancelUrl, onCancel }: FiscalCodeModalProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleCancel = (event: React.MouseEvent<HTMLAnchorElement>) => {
        setToSessionCache('showFiscalCodeModalPreference', 'false');

        onCancel && onCancel(event);

        onClose();
    };

    return (
        <Modal isOpen={isOpen} onModalClose={() => onClose()}>
            <div className="space-y-5 break-words" data-testid="fiscal-code-modal">
                <Heading size={8}>{t('payment.modal.fiscalcode.title')}</Heading>
                <Text size={2} className="leading-[130%]">
                    {t('payment.modal.fiscalcode.description')}
                </Text>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Button
                        onClick={() => navigate('/information/change-fiscal-code')}
                        data-testid="fiscal-code-modal-confirm"
                    >
                        {t('payment.modal.fiscalcode.submit')}
                    </Button>
                    <a
                        onClick={handleCancel}
                        href={cancelUrl}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="fiscal-code-modal-cancel"
                    >
                        <Button variant="secondary" className="w-full">
                            {t('payment.modal.fiscalcode.cancel')}
                        </Button>
                    </a>
                </div>
            </div>
        </Modal>
    );
};
