import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Text } from '@basic-fit/design-system';
import { useMember } from '@src/services/member/MemberProvider';
import DOMPurify from 'isomorphic-dompurify';

import Popup from 'components/partials/Popup';
import PopupTitle from 'components/partials/PopupTitle';
import googleTagManagerPush from 'services/google-tag-manager-push';
import { Button } from '../Buttons/Button';
import ArrowRightIcon from '../Icons/icons/ArrowRight.icon';

interface MyInformationBlockProps extends React.PropsWithChildren {
    title: string;
    dataCy: string;
    testId?: string;
    buttonTo: string;
    shouldSendGA?: boolean;
}

export const MyInformationBlock = ({
    children,
    dataCy,
    testId,
    title,
    buttonTo,
    shouldSendGA
}: MyInformationBlockProps): JSX.Element => {
    const [showPopup, setShowPopup] = useState(false);
    const { t } = useTranslation();
    const { state: member } = useMember();
    const navigate = useNavigate();

    const isNotAllowedToChange = !member.iban && buttonTo === '/information/change-email';

    function onClick() {
        if (isNotAllowedToChange) {
            window.newrelic?.addPageAction('member has no iban', {
                peopleId: member.id_g
            });
            setShowPopup((prev) => !prev);
        }
        if (shouldSendGA) {
            googleTagManagerPush.clickEvent('change email');
        }
    }

    return (
        <>
            <Link
                className="flex justify-between items-center py-3"
                to={isNotAllowedToChange ? '' : buttonTo}
                onClick={onClick}
                data-cy={dataCy}
                data-testid={testId}
            >
                <div>
                    <div className="flex flex-col text-grey-disabled break-all">
                        <Text size={2} bold>
                            {title}
                        </Text>
                        {children}
                    </div>
                </div>
                <div className="text-orange w-6 h-6 p-1">
                    <ArrowRightIcon />
                </div>
            </Link>
            {showPopup && (
                <Popup
                    Contents={() => (
                        <div
                            className="relative flex flex-col gap-6 justify-between"
                            data-cy="no-iban-popup"
                            data-testid="no-iban-popup"
                        >
                            <PopupTitle
                                text={t('update.your.iban')}
                                stroke="first"
                                onClick={onClick}
                            />
                            <Text
                                size={2}
                                className="leading-[130%]"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(t('no.iban.provided'))
                                }}
                            />
                            <Button
                                onClick={() => {
                                    setShowPopup((prev) => !prev);
                                    navigate('/information/change-payment-info');
                                }}
                                text={t('add.iban')}
                            />
                            <Button text={t('close')} onClick={onClick} color="naked" />
                        </div>
                    )}
                />
            )}
        </>
    );
};
